import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Container, Image, Card } from 'react-bootstrap'
import {Helmet} from "react-helmet";
import './app.scss';
import salvation from './images/Bible Verse.jpg';
import Share from './ShareGeneric';
import word from './docs/An Intuitive Case for Jesus Dire Words.docx';
import pdf from './docs/An Intuitive Case for Jesus Dire Words.pdf';
import youtube from './images/youtube_icon.png';


function Dire() {

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('G-P248LQSJST');
    }
  }, []);

  const gaTrack = (category, action) => {
    if (process.env.NODE_ENV === 'production') {
        ReactGA.event({ category, action })
    }
  }
  return (
    <>
      <Helmet>
        {/* 
            To get SEO to work for other pages, e.g., salvation, 
            must serve index.html page content from node server, e.g., a lambda,
            substituting og meta tags. This should work if direct link,
            but maybe not if react router. Though not sure latter matters.
            Too bad services don't wait for JS to alter meta tags.
        */}
        <title>An Intuitive Case &#124; Intentionally Catholic</title>
      </Helmet>
      <Image src={salvation} className="img-fluid rounded" title="An Intuitive Case for Jesus' Dire Words" />
      <header>
        <div className="text-center article article-title" >
        An Intuitive Case for Jesus&apos; Dire Words
        </div>
        <div className="text-center article article-by" >
          May 2022
        </div>
      </header>
      <div className="text-center">
        <div style={{ fontSize: "1.1rem", marginTop: "1rem", marginBottom: "0rem" }}>
          <a href="https://youtu.be/2wrPySQKSlA"
            target="_blank" rel="noopener noreferrer"
            onClick={() => gaTrack('Resources', 'Dire-YouTube')}
            style={{ fontStyle: "italic" }}
          >
          <Image src={youtube}
              className="img-fluid rounded"
              title="Video: An Intuitive Case for Jesus Dire Words"
              width="32px"
              style={{marginRight: "0.5rem"}}
            />
            (Listen)
          </a>
          </div>
      </div>
      <div className="text-center">
        <div style={{ fontSize: "1.1rem", marginTop: "1rem", marginBottom: "0rem" }}>
          <span className="article-link-span" style={{ fontWeight: "bold" }}>Download: </span>
          <span className="article-link-span" >
            <a href={word} onClick={() => gaTrack('Resources', 'Dire-Word')}
            >Word&nbsp;doc</a></span>
          <span className="article-link-span" >
            <a href={pdf}
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'Dire-Pdf')}
            >Pdf</a></span>
          <span className="article-link-span">
            <a href="https://docs.google.com/document/d/13bs1J2UurJR52YPh9GcafX6dIJsTXLRM66vMmGmu9mo/edit?usp=sharing"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'Dire-GoogleDoc')}
            >Google&nbsp;doc</a></span>
        </div>
      </div>

      <div className="book-body article-section" >

        <div className="article article-body">
          <p className="article-para" />

          <p className="article-para" style={{ fontStyle: "italic", fontSize: "125%", marginBottom: "1.5rem", marginTop: "1.5rem" }} >
            Contrary to popular &ldquo;feelings,&rdquo; this article considers 
            the accuracy of Jesus&apos; words in light of how much God loves us, 
            the crucifixion of his only beloved son, and the intimate 
            reality of heaven.
          </p>

          <p className="article-para">
          Even deacons, priests, and bishops have loved ones who have drifted away from the faith and believe going to church is unnecessary, such as, grown children, siblings, parents, nieces, nephews, and best friends. But when we think about it, this is not surprising. What does strike me, however, is that clergy are not explicitly concerned about their loved ones’ salvation. It’s never discussed. No formal meetings about it. Rarely a conversation among clerical friends. It is as if everyone believes that, in the end, God will save everyone albeit probably after a stint in purgatory. 
          Cardinal George Pell, though, had a recent change of heart on this. In the <a href="https://www.firstthings.com/article/2020/12/last-things" target="_blank" rel="noopener noreferrer" >December 2020</a> issue of <span style={{ fontStyle: "italic" }}>First Things</span>, Cardinal Pell explained how he revisited Jesus’ words and realized there was insufficient support for his optimism that nearly everyone would be saved.
          </p>

          <p className="article-para">
          The theological position that it is reasonable to hope everyone is saved and hell is empty became more popular in Catholic circles in the late 80’s and 90’s with Hans Urs von Balthasar’s book, <span style={{ fontStyle: "italic" }}>Dare We Hope “That All Men Be Saved.”</span> Ralph Martin, in his book, <span style={{ fontStyle: "italic" }}>A Church in Crisis: Pathways Forward,</span> summarized Balthasar’s theory that “it may be indeed virtually impossible that human beings can finally resist the grace of God.” (p.75) Then Martin added that theologians supporting Balthasar’s theory often include a statement like, “I cannot accept a God who would send anyone to hell.” (p.76) – a judgement based on one’s personal outlook and not God’s actual words.
          </p>

          <p className="article-para">
          When the New Testament is quoted to make a case that everyone is saved, it will probably ignore the context or other verses on judgment and condemnation. However, all words in Sacred Scripture are God’s words and true. Hence, they must be understood in relation to each other. For instance, Paul wrote, “[Jesus’] righteousness leads to acquittal and life for all men.” (Romans 5:18b) But he also wrote, “I warn you, as I warned you before, that those who do such things shall not inherit the kingdom of God.” (Galatians 5:21b) Official Church teaching for the past 2000 years carefully considers all God’s words and keeps them reconciled, like notes in a symphony. In fact, Sacred Scripture should be the soul of theology. (<a href="https://www.usccb.org/sites/default/files/flipbooks/catechism/38/" target="_blank" rel="noopener noreferrer" >CCC 132</a>) Therefore, in accord with Church teaching, this article intends to show that Jesus’ <span style={{ fontStyle: "italic" }}>dire</span> words make sense on an intuitive level, and thus should not be watered down or ignored, while also affirming merciful hope that comes through intercessory prayer.
          </p>

          <div className="article-sub-title">An Underlying Feeling</div>

          <p className="article-para">
          Beneath the reasonable hope and widespread optimism that just about everyone is good enough and will eventually enter heaven is a common feeling among the baptized – preachers, teachers, and laity. This feeling flows from a deep belief that God is simply “love,” as popularly defined, and therefore cannot damn anyone. Occasionally, when caused to ponder for a moment, this belief is reinforced by a quick intuitive calculation that if God is love, then he cannot deny salvation to people who are basically “good in our eyes” just because they did not live up to all Jesus’ words – for instance, those who seem kind and generous but did not care to know Jesus. Some of whom, incidentally, might be friends and loved ones who are dear to our own hearts. Consequently, the obvious meaning of Jesus’ dire words cannot be true. This is why people do not want to face all his words, and why most teachers of the faith will skip over or water them down. To put it another way, Jesus’ clear words about what is required for heaven are incongruous with what we feel is right and just.
          </p>

          <p className="article-para">
          But can this be a situation where we are “[feeling] not as God does, but as human beings do”? (Matthew 16:23b) Now of course, God loves everyone infinitely more than the most perfect father on earth. The issue is, as with fallen angels, will people choose to know and love God?
          </p>

          <div className="article-sub-title">Jesus’ Difficult Words, Fatima, and Saint Faustina</div>

          <p className="article-para">
          In the Gospels, there are many instances where Jesus spoke clearly about the existence of hell, possibility of damnation, requirements for eternal life, and divine judgement. A few brief examples will suffice. In the final judgement scene, Jesus referred to “the eternal fire prepared for the devil and his angels.” (Matthew 25:41) About damnation, he said, “fear him who can destroy both soul and body in hell.” (Matthew 10:28) On requirements for salvation, we must do the will of his Father who is in heaven. (Matthew 7:21) “He who does not believe is condemned already.” (John 3:36b) For half the virgins who were not prepared when the Lord arrived, the door was locked. (Matthew 25:10-12)
          </p>

          <p className="article-para">
          Remember when Our Lady appeared to the three children at Fatima. After one vision she said, “You have seen hell where the souls of poor sinners go.” And recall our Lord’s visions to Saint Faustina of the Divine Mercy. She, too, was given a vision of hell. (<a href="http://www.seraphim.my/divinemercy/diary/text/DiaryII(701-750).htm" target="_blank" rel="noopener noreferrer" >Diary 741</a>) Reminiscent of Jesus’ warning, “the gate is narrow and the way is hard, that leads to life, and those who find it are few,” (Matthew 7:14) Faustina wrote about two roads. Countless people walked on the broad pleasurable one and fell into the abyss of hell, while others suffered on the narrow path until they reached a garden full of happiness. (<a href="http://www.seraphim.my/divinemercy/diary/text/DiaryI(151-200).htm" target="_blank" rel="noopener noreferrer" >Diary 153</a>)
          </p>

          <p className="article-para">
          When we start applying these dire words to ourselves, and to others we know and love, these teachings are almost impossible to accept on a gut level. They are too terrifying, too permanent, and seem disproportionately unjust and unfair.
          </p>

          <div className="article-sub-title">An Intuitive Case</div>

          <p className="article-para">
          Nevertheless, though unsettling, consider a traditional view of salvation that is contrary to popular sentiment, accepts the accuracy of Jesus’ words, and is based on how much God loves us, the crucifixion of his only beloved Son, and the intimate reality of heaven.
          </p>

          <p className="article-para">
          “For God so loved the world that he gave his only son...” (John 3:16a) Consider how God the Father felt as he watched his son getting tortured and crucified for hours; and as he saw his son’s mother, crushed by grief. Now, how does God the Father feel as many busy people neglect and forget Jesus?            
          </p>

          <p className="article-para">
          Consider also, what God has offered mankind. Jesus prayed, “Father, as you are in me and I am in you, may they be one in us.” (John 17:21) That is, heaven is intimate communion with the Holy Trinity, God in us and us in God, “partakers of [His] divine nature.” (2 Peter 1:4b) Consequently, how can people expect to jump into loving intimacy with God, having never spent time getting to know him? Indeed, having no interest in him; and living their lives as if God did not exist and Jesus was never born.
          </p>

          <p className="article-para">
          Remember as well, how Jesus summed up all the laws of God. He taught, “You shall love the Lord your God with all your heart, and with all your soul, and with all your mind. This is the great and first commandment.” (Matthew 22:37-38) But how can people be obeying the great and first commandment without having a personal relationship with Jesus and listening to his words? When they pass churches daily without going in? When seeing a crucifix or cross does not cause them to pause and reconsider? When they ignore that loving relative or friend who regularly practices his faith and never question him about it?
          </p>

          <p className="article-para">
          Moreover, when people do not know Jesus, and their moral conscience has not been formed by the Church, how frequently will they seriously offend God by believing what is bad is good, and what is good is bad? This sad plight is recognized by the Church. “Very often, deceived by the Evil One, men have become vain in their reasonings, have exchanged the truth of God for a lie, and served the world rather than the Creator.” (<a href="https://www.vatican.va/archive/hist_councils/ii_vatican_council/documents/vat-ii_const_19641121_lumen-gentium_en.html" target="_blank" rel="noopener noreferrer" >Lumen Gentium</a> 16d) What a frightening situation for the world as well as their salvation!
          </p>

          <p className="article-para">
          When God, in a sense, “visits” his people and provides opportunities to believe, and they fail to respond, punishment can be severe. Remember when Jesus sent his Apostles to the lost sheep of Israel saying, “If they will not listen to your words, shake the dust off your feet when you leave them. It will be more tolerable on the day of judgement for Sodom and Gomorrah than for that town.” (Matthew 10:14-15) Or when Jesus said, “O Jerusalem… how often would I have gathered your children together as a hen gathers her brood under her wings, and you would not!” (Matthew 23:37) Then when Jesus drew near Jerusalem, he wept over her and lamented, “for the days will come... when you and your children will be [destroyed] because you did not know the time of your visitation.” (Luke 19:41-44) Finally, when Jesus commissioned his Apostles he said, “Go into all the world and preach the gospel to the whole creation. He who believes and is baptized will be saved; but he who does not believe will be condemned.” (Mark 16:15-16) In a variety of ways, God has visited his people and given them chances to reconsider, but they did not want a loving relationship with him.
          </p>

          <p className="article-para">
          In “<a href="https://www.firstthings.com/article/2008/02/001-who-can-be-saved-8" target="_blank" rel="noopener noreferrer" >Who Can Be Saved?</a>” (First Things 2008), Jesuit Cardinal Avery Dulles wrote, “Some, perhaps many, resist the grace of God and reject the signs given to them. They are not on the road to salvation at all. In such cases, the fault is not God’s but theirs. The references to future punishment in the Gospels cannot be written off as empty threats. As Paul says, God is not mocked (Galatians 6:7).” The Church teaches, “If [Catholics] fail to respond in thought, word and deed to grace, not only will they not be saved, but they will be judged more severely.” (<a href="https://www.vatican.va/archive/hist_councils/ii_vatican_council/documents/vat-ii_const_19641121_lumen-gentium_en.html" target="_blank" rel="noopener noreferrer" >Lumen Gentium</a> 14b)
          </p>

          <p className="article-para">
          On the critical possibility of losing salvation, many were lulled to sleep in recent decades, and have not woken up.
          </p>

          <div className="article-sub-title">Culpability &amp; Hope</div>

          <p className="article-para">
          Now one might ask, are there mitigating circumstances that can diminish culpability for those not practicing the faith? Of course, there are many, like having unconcerned preachers and teachers, scandals, being raised in difficult circumstances, emotional problems, addiction, and so forth. This is why only God can judge – “Father, forgive them; for they know not what they do.” (Luke 23:34)
          </p>

          <p className="article-para">
          Importantly, this uninformed and detrimental situation can provide some hope when a loved one passes to God who has not known Jesus through little fault of his own. It is especially hopeful when the deceased’s loved ones are praying for God’s mercy. The problem, however, is when everyone presumes upon this mercy and fails to care and pray. In the very end, though, we might have to be prepared to share in our heavenly Father’s suffering when his children freely chose not to love in return.
          </p>

          <div className="article-sub-title">Conclusion</div>

          <p className="article-para">
          I am dumfounded by the dearth of explicit concern about salvation among clergy. Every day they proclaim God’s words and often preach. Every morning, they probably pray Psalm 95, the default option for the Invitatory. It ends, “They are a people whose hearts go astray, and they do not know my ways. So, I swore in my anger, ‘They shall not enter into my rest.’” How can we pray that every day and not be affected by it? How can we proclaim Jesus’ words to people, and teach about them, but still not express concern about salvation?
          </p>

          <p className="article-para">
          This article is fundamentally about believing all Jesus’ words, even his more difficult ones. We cannot rely on clergy to tell us what we need to know about God. Rather, we must know the Gospels and Catechism ourselves. Pray for salvation. And try to wake our loved ones up through prayer and conversation. For those with severe problems, we must encourage them to turn toward the power of God.            
          </p>

          <p className="article-para">
          We must also pray for those who have gone before us without knowing Jesus. May God have mercy on those poorly informed by preachers and teachers of the faith. And when it is our time to stand before the Lord, may there be loved ones who believe that are praying for us.
          </p>


          <Share 
            url = "https://IntentionallyCatholic.com/Dire"
            title = "An Intuitive Case for Jesus' Dire Words"
            subject = "An Intuitive Case for Jesus' Dire Words"
            body = ""
            caption = "Share this article with those who care."        
          />

        </div>

        <div className="book-section0">
          <div className="book-section1 contact-info">Contact Information</div>
          <div className="book-description">
            Please contact me at:
            <br className="show-hide" /> <a href="mailto: DeaconJohnBeagan@gmail.com">DeaconJohnBeagan@gmail.com</a>
            <br className="show-hide" /> if you have any questions or feedback.
            <br />God bless!
            <div className="signature">
              Deacon John Beagan
            </div>
            <div className="serving">
              Serving Sacred Heart &amp; Saint Patrick parishes in Watertown, MA
            </div>
            <div className="serving">
              Archdiocese of Boston
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default Dire;

import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import {Helmet} from "react-helmet";
import { Image } from 'react-bootstrap'
import './app.scss';
import forThoseYouLove from './images/For-Those-You-Love-Web-2.jpg';
import Share from './ShareForThose';
import fromSomeone from './images/From Someone Who Loves You.jpg';
import itMakesSense from './images/It Makes Sense.jpg';

function ForThoseYouLove() {

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('G-P248LQSJST');
    }
  }, []);

  const gaTrack = (category, action) => {
    if (process.env.NODE_ENV === 'production') {
        ReactGA.event({ category, action })
    }
  }

  return (
    <>
      <Helmet>
        <title>For Those You Love &#124; Intentionally Catholic</title>
      </Helmet>

      <Image src={forThoseYouLove} className="img-fluid rounded" title="For Those You Love" />

      <div className="book-body" >


        <div className="book-section0" style={{marginTop: "2rem", marginBottom: "3rem"}}>
          <div className="book-section1 book-it-makes-sense" >
            Share This Video with Family &amp; Friends
          </div>
          <div className="book-description">
            As a deacon in town where I grew up and raised my children, I am uncomfortable 
            watching families and generations drift away from church and forget Jesus. So share 
            this video with loved ones and start a conversation. Peace.
          </div>
          <div className="book-links">
            <a href="https://youtu.be/GQb7dBQy6lM"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'ForThoseYouLove-ClickedVideo')}
              >
              <Image src={fromSomeone} 
                    className="img-fluid rounded" 
                    title="From Someone Who Loves You"
                    style={{border: "1px solid #DCDCDC"}}
                    />
              </a>
            <div className="book-span">(11 minutes)</div>
          </div>
        </div>

        <div className="book-section0" style={{marginTop: "2rem"}}>
          <div className="book-section1 salvation-crisis" style={{ fontStyle: "italic" }}>
            It Makes Sense to Believe
          </div>
          <div id="" className="subsection">
            <div id="" className="book-section2" >
              For those who doubt or disbelieve...
            </div>
            <div id="" className="book-section2b" >
              or are lukewarm and disinterested...
            </div>
            <div className="book-cover" >
              <a href="https://www.amazon.com/dp/B08BWFKBDK?ref_=pe_3052080_397514860"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'ForThose-CoverClickItMakesSense')}
                style={{ textDecoration: "none" }}>
                <Image src={itMakesSense}
                  className="img-fluid rounded"
                  title="It Makes Sense to Believe"
                />
              </a>
            </div>
            <div className="book-button-outer" >
              <div className="book-links">
                <a href="https://www.itmakessensetobelieve.com"
                  target="_blank" rel="noopener noreferrer"
                  onClick={() => gaTrack('Resources', 'ForThose-SiteLinkItMakesSense')}
                  style={{ textDecoration: "none" }}>
                  <span
                    className="book-button"
                    style={{ backgroundColor: "#9b0000" }}
                  >
                    Goto Book Website
                </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="book-section0" style={{ marginTop: "5rem", marginBottom: "6rem" }}>
          <div className="book-section1 book-passing-the-faith" >
            Intentionally Catholic
          </div>
          <div className="book-description">
            Resources for clergy and laity to shore up the faith 
            of parishioners and help pass the faith on to loved ones.
          </div>
          <div className="book-button-outer" >
            <div className="book-links">
              <a href="https://www.IntentionallyCatholic.com"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'Intentionally-ForThose')}
                style={{ textDecoration: "none" }}>
                <span
                  className="book-button"
                  style={{ backgroundColor: "#ca8e4c" }}
                >
                  Goto Main Page
                </span>
              </a>
            </div>
          </div>
        </div>



        <Share />


        <div className="book-section0">
          <div className="book-section1 contact-info">Contact Information</div>
          <div className="book-description">
            Please contact me at:
            <br className="show-hide" /> <a href="mailto: DeaconJohnBeagan@gmail.com">DeaconJohnBeagan@gmail.com</a>
            <br className="show-hide" /> if you have any questions or feedback. 
            <br />God bless!
            <div className="signature">
              Deacon John Beagan
            </div>
            <div className="serving">
              Serving Sacred Heart &amp; Saint Patrick parishes in Watertown, MA
            </div>
            <div className="serving">
              Archdiocese of Boston
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default ForThoseYouLove;

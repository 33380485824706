import React from 'react';
import './share.scss';
import {TwitterShareButton, TwitterIcon, EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon } from 'react-share';

function ShareHelpBelieve()  {

  return (
    <div className="share-container">
    <FacebookShareButton className="share-venue" url="https://intentionallycatholic.com/Help-Them-Believe" >
      <FacebookIcon size={"2.5rem"} borderRadius={"8px"} />
    </FacebookShareButton>
    <TwitterShareButton className="share-venue" url="https://intentionallycatholic.com/Help-Them-Believe" 
      title="Help Them Believe">
      <TwitterIcon size={"2.5rem"} borderRadius={"8px"} />
    </TwitterShareButton>
    <EmailShareButton  className="share-venue" 
      url="https://intentionallycatholic.com/Help-Them-Believe"
      subject="Help Them Believe"
      body="I'm sharing this page with you because you care about helping people believe."
    >
      <EmailIcon size={"2.5rem"} borderRadius={"8px"} />
    </EmailShareButton>
    <span className="show-hide"><br /></span><span style={{fontStyle: "italic", fontSize: "1.2rem"}}>
      Share this page with those who care about helping people believe.</span>
  </div >
  )

}

export default ShareHelpBelieve;

import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Container, Image, Card } from 'react-bootstrap'
import {Helmet} from "react-helmet";
import './app.scss';
import help from './images/storm-boat-web.jpg';
import Share from './ShareHelpBelieve';
import itMakesSense from './images/It Makes Sense.jpg';
import passingTheFaith from './images/Passing the Faith.jpg';
import fromSomeone from './images/From Someone Who Loves You.jpg';


function HelpThemBelieve() {

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('G-P248LQSJST');
    }
  }, []);

  const gaTrack = (category, action) => {
    if (process.env.NODE_ENV === 'production') {
        ReactGA.event({ category, action })
    }
  }

  return (
    <>
      <Helmet>
        <title>Help Them Believe &#124; Intentionally Catholic</title>
      </Helmet>

      <Image src={help} className="img-fluid rounded" title="Help Them Believe" />

      <div className="book-body" >

        <div className="book-section0">
          <Card bg="light">
            <Card.Body style={{ paddingBottom: "0rem" }}>
              <Card.Text>
                <p className="ralph-quote">
                  Endorsed by <span style={{ fontWeight: "bold" }}>
                    Ralph Martin
                  </span>  whose latest book is <span style={{ fontStyle: "italic" }}>
                    A Church in Crisis: Pathways Forward
                  </span>.
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </div >

        <div className="book-section0" style={{ marginTop: "2rem", marginBottom: "3rem" }}>
          <div className="book-section1 book-the-book" >
            The Situation
          </div>
          <div className="book-description">

            <p>As the deacon in town where I grew up and raised my children, I am saddened at
            Mass when I look out at the pews and see so many empty seats. I know there
            are many Catholics in town who drifted away. Their children have no connection
            to the Church and their grandchildren will not be baptized. They may never
            come to know Jesus.</p>

            <p>Disbelief is the major cause. According to Pew Research, the biggest reason for 
            people leaving the Catholic faith is they "stopped believing." Moreover, many coming 
            to Mass have doubts or disagree with various Church teaching which undermines their enthusiasm. 
            Others simply feel coming to church is not necessary.</p>

          </div>
        </div>

        <div className="book-section0" style={{ marginTop: "2rem", marginBottom: "3rem" }}>
          <div className="book-section1 book-back-cover" >
            The Help
          </div>
          <div className="book-description">

            <p>Thus, I felt compelled to write a short, easy to read, book to help people
            fully believe. And YouTube <a href="https://www.youtube.com/channel/UCSzqrptQ-DiouAPUfkXtk4w/videos"
                  target="_blank" rel="noopener noreferrer"
                  onClick={() => gaTrack('Resources', 'Help-YouTubeChannel')}
                  style={{ fontStyle: "italic", fontWeight: "bold" }}>
                  videos!
                </a> It weaves together a simple case for the faith with anecdotes from what
            I've experienced and learned. It also deals with today's chief criticisms and will take
            less than 3 hours to read. This was written with individuals and parishes in mind.
            </p>

            <p>The book comes in two flavors; only 3 chapters
            differ. <span style={{ fontStyle: "italic" }}>It Makes Sense to Believe</span> was
            tailored for those who disbelieve or are lukewarm. <span style={{ fontStyle: "italic" }}>
            Passing the Faith onto Loved Ones</span> is meant for those who love the Church.
            Ralph Martin read and endorsed this one.</p>

            <Card bg="light">
              <Card.Body style={{ paddingBottom: "0rem" }}>
                <Card.Text>
                  <p className="ralph-quote"><span style={{ fontWeight: "bold" }}>&ldquo;</span>
                  Deacon John is a clear thinker and clear writer and has written a book
                  that will be a real contribution to Passing the Faith onto Loved Ones.
                  <span style={{ fontWeight: "bold" }}>&rdquo;</span>
                  </p>
                  <p><span style={{ fontWeight: "bold" }}>~ Ralph Martin, S.T.D.</span></p>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>

        <div className="book-section0" style={{ marginBottom: "2rem" }}>
          <div className="book-section1 book-it-makes-sense" style={{ fontStyle: "italic" }}>
            It Makes Sense to Believe
          </div>
          <div id="" className="subsection">
            <div id="" className="book-section2" >
              For those who doubt or disbelieve. Or are lukewarm and disinterested.
            </div>
            <div id="" className="book-description" >
              Ideal for confirmation students &mdash; before they go to college! And it
              can be taught in one or two sessions. Likewise, for the parents of students
              in faith formation since they are the primary educators. Finally, it can be 
              made available to all parishioners.
            </div>
            <div className="book-cover" >
              <a href="https://www.amazon.com/dp/B08BWFKBDK?ref_=pe_3052080_397514860"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'HelpItMakesSenseCover')}
                style={{ textDecoration: "none" }}>
                <Image src={itMakesSense}
                  className="img-fluid rounded"
                  title="It Makes Sense to Believe"
                />
              </a>
            </div>

            <div className="book-button-outer" >
              <div className="book-links">
                <a href="https://www.amazon.com/dp/B08BWFKBDK?ref_=pe_3052080_397514860"
                  target="_blank" rel="noopener noreferrer"
                  onClick={() => gaTrack('Resources', 'HelpItMakesSenseButton')}
                  style={{ textDecoration: "none" }}>
                  <span
                    className="book-button"
                    style={{ backgroundColor: "#088c9b" }}
                  >
                    Amazon Books
                </span>
                </a>
                <div className="book-price">(Kindle 4.95 US, Paperback 7.95 US)</div>
              </div>
            </div>

          </div>
        </div>


        <div className="book-section0" style={{ marginBottom: "2rem" }}>
          <div className="book-section1 book-passing-the-faith" style={{ fontStyle: "italic" }}>
            Passing the Faith onto Loved Ones
          </div>
          <div id="" className="subsection">
            <div id="" className="book-section2" >
              For those who love the Church.
            </div>
            <div id="" className="book-description" >
              Best for teachers, ministers, staff, and other enthusiastic laypeople. Good
              for anyone who wants to pass the faith on to a loved one.
            </div>
            <div className="book-cover" >
              <a href="https://www.amazon.com/dp/1659148014?ref_=pe_3052080_397514860"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'HelpPassingTheFaithCover')}
                style={{ textDecoration: "none" }}>
                <Image src={passingTheFaith}
                  className="img-fluid rounded"
                  title="Passing the Faith onto Loved Ones"
                />
              </a>
            </div>

            <div className="book-button-outer" >
              <div className="book-links">
                <a href="https://www.amazon.com/dp/1659148014?ref_=pe_3052080_397514860"
                  target="_blank" rel="noopener noreferrer"
                  onClick={() => gaTrack('Resources', 'HelpPassingTheFaithButton')}
                  style={{ textDecoration: "none" }}>
                  <span
                    className="book-button"
                    style={{ backgroundColor: "#ca8e4c" }}
                  >
                    Amazon Books
                </span>
                </a>
                <div className="book-price">(Kindle 4.95 US, Paperback 7.95 US)</div>
              </div>
            </div>

          </div>
        </div>

        <div className="book-section0" style={{ marginBottom: "4rem" }}>
          <div className="book-section1 book-back-cover">For Those You Love</div>
          <div className="book-description">
            This video is an invitation to send to fallen-away loved ones 
            so they might consider the books' simple case for the faith 
            and come to Mass. 
          </div>

          <div className="book-links">
            <a href="https://youtu.be/GQb7dBQy6lM"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'FromSomeone-Help')}
              >
              <Image src={fromSomeone} 
                    className="img-fluid rounded" 
                    title="From Someone Who Loves You"
                    style={{border: "1px solid #DCDCDC"}}
                    />
              </a>
            <div className="book-span">(11 minutes)</div>
          </div>
        </div>

          <div className="book-section0" style={{ marginBottom: "6rem" }}>
            <div className="book-section1 salvation-crisis" >
              Intentionally Catholic
            </div>
            <div className="book-description">
              Resources for clergy and laity to shore up the faith 
              of parishioners and help pass the faith on to loved ones.
            </div>
            <div className="book-button-outer" >
              <div className="book-links">
                <a href="https://www.IntentionallyCatholic.com"
                  target="_blank" rel="noopener noreferrer"
                  onClick={() => gaTrack('Resources', 'Intentionally-Help')}
                  style={{ textDecoration: "none" }}>
                  <span
                    className="book-button"
                    style={{ backgroundColor: "#9b0000" }}
                  >
                    Goto Main Page
                  </span>
                </a>
              </div>
            </div>
          </div>
          
        <Share />


        <div className="book-section0">
          <div className="book-section1 contact-info" style={{ marginTop: "4rem" }}>
            Contact Information</div>
          <div className="book-description">
            <div >
              <span style={{ color: "red" }}>U.S. bulk orders can be arranged. Contact me.
              </span> <span style={{ fontStyle: "italic" }}>Also -- videos, narrations, and manuscripts are freely available on the books'
              websites. Go to <a href="https://www.IntentionallyCatholic.com"
                  target="_blank" rel="noopener noreferrer"
                  onClick={() => gaTrack('Resources', 'HelpIntentionallyCatholicLink')}
                  style={{ fontStyle: "italic", fontWeight: "400" }}>
                  IntentionallyCatholic.com
                </a>.
              </span>
            </div>
          </div>
          <div className="book-description">
            Please contact me at:
            <br className="show-hide" /> <a href="mailto: DeaconJohnBeagan@gmail.com">DeaconJohnBeagan@gmail.com</a>
            <br className="show-hide" /> if you have any questions or feedback.
            <br />God bless!
            <div className="signature">
              Deacon John Beagan
            </div>
            <div className="serving">
              Serving Sacred Heart &amp; Saint Patrick parishes in Watertown, MA
            </div>
            <div className="serving">
              Archdiocese of Boston
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default HelpThemBelieve;

import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import ReactGA from 'react-ga4';
import { Navbar, Nav, Container, Image, Card } from 'react-bootstrap'
import './app.scss';
import rose from './images/rose.svg';
import Main from './Main';
import Salvation from './Salvation';
import Clergy from './Clergy';
import Crisis from './Crisis';
import Army from './Army';
import Sharing from './Sharing';
import Dire from './Dire';
import ForThoseYouLove from './For-Those-You-Love';
import HelpThemBelieve from './HelpThemBelieve';
import OurLadyOfPerpetualHelp from './OurLadyOfPerpetualHelp';

function App() {

  //old: UA-168892917-3
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('G-P248LQSJST');
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, []);

  const gaTrack = (category, action) => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.event({ category, action })
    }
  }

  return (
    <>
      <Navbar collapseOnSelect fixed='top' expand='sm' bg='light' variant='light' >
        <Container >
          {/* NAV */}
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav' >
            <Nav>
              <Nav.Link href='/'>Home</Nav.Link>
              <Nav.Link href='/Believe'>Believe</Nav.Link>
              <Nav.Link href='/Clergy'>Clergy</Nav.Link>
              <Nav.Link href='/Salvation'>Salvation</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* end nav */}

      <Container className="book-container">
        <Router>
          <Switch>
            <Route exact path="/clergy">
              <Clergy />
            </Route>
            <Route exact path="/salvation">
              <Salvation />
            </Route>
            <Route exact path="/help-them-believe">
              <HelpThemBelieve />
            </Route>
            <Route exact path="/for-those-you-love">
              <ForThoseYouLove />
            </Route>
            <Route exact path="/crisis">
              <Crisis />
            </Route>
            <Route exact path="/help">
              <OurLadyOfPerpetualHelp />
            </Route>
            <Route exact path="/believe">
              <OurLadyOfPerpetualHelp />
            </Route>
            <Route exact path="/army">
              <Army />
            </Route>
            <Route exact path="/dire">
              <Dire />
            </Route>
            <Route exact path="/share">
              <Sharing />
            </Route>
            <Route path="/">
              <Main />
            </Route>
          </Switch>
        </Router>

        <footer className="book-footer text-center">
          <div>
            <a href="https://www.IntentionallyCatholic.com"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'IntentionallyCatholicLink')}
              style={{ fontStyle: "italic", fontWeight: "400" }}>
              Intentionally Catholic .com
            </a>
          </div>
          <div className="rosie">
            <a href="https://www.RosieBeagan.com"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'RosieLink')}
              style={{ fontStyle: "italic", fontWeight: "500" }}>
              <Image src={rose}
                className="img-fluid rounded"
                title="Rose Elizabeth Foundation"
                width="40px"
              />
              Rosie
            </a>
          </div>
        </footer>

      </Container>
    </>
  );
}

export default App;

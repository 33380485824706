import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Container, Image, Card } from 'react-bootstrap'
import {Helmet} from "react-helmet";
import './app.scss';
import salvation from './images/Salvation-Church-in-Crisis.jpg';
import Share from './ShareSalvation';
import word from './docs/Salvation - Church in Crisis.docx';
import pdf from './docs/Salvation - Church in Crisis.pdf';
import youtube from './images/youtube_icon.png';


function Salvation() {

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('G-P248LQSJST');
    }
  }, []);

  const gaTrack = (category, action) => {
    if (process.env.NODE_ENV === 'production') {
        ReactGA.event({ category, action })
    }
  }
  return (
    <>
      <Helmet>
        {/* 
            To get SEO to work for other pages, e.g., salvation, 
            must serve index.html page content from node server, e.g., a lambda,
            substituting og meta tags. This should work if direct link,
            but maybe not if react router. Though not sure latter matters.
            Too bad services don't wait for JS to alter meta tags.
        */}

        <title>Salvation Crisis &#124; Intentionally Catholic</title>
      </Helmet>
      <Image src={salvation} className="img-fluid rounded" title="Salvation: Church in Crisis" />
      <header>
        <div className="text-center article article-title" >
          Salvation: Church in Crisis
        </div>
        <div className="text-center article article-by" >
          February 2021
        </div>
      </header>
      <div className="text-center">
        <div style={{ fontSize: "1.1rem", marginTop: "1rem", marginBottom: "0rem" }}>
          <a href="https://youtu.be/PHJmtRYd4XA"
            target="_blank" rel="noopener noreferrer"
            onClick={() => gaTrack('Resources', 'Salvation-Crisis-YouTube')}
            style={{ fontStyle: "italic" }}
          >
          <Image src={youtube}
              className="img-fluid rounded"
              title="Video: Salvation - Church in Crisis"
              width="32px"
              style={{marginRight: "0.5rem"}}
            />
            (Watch)
          </a>
          </div>
      </div>
      <div className="text-center">
        <div style={{ fontSize: "1.1rem", marginTop: "1rem", marginBottom: "0rem" }}>
          <span className="article-link-span" style={{ fontWeight: "bold" }}>Download: </span>
          <span className="article-link-span" >
            <a href={word} onClick={() => gaTrack('Resources', 'Salvation-Word')}
            >Word&nbsp;doc</a></span>
          <span className="article-link-span" >
            <a href={pdf}
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'Salvation-Pdf')}
            >Pdf</a></span>
          <span className="article-link-span">
            <a href="https://docs.google.com/document/d/1Uyl-PfiJbodEcYhc2V0WL5puTkyw3RrHYFB4QQ15YY4/edit?usp=sharing"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'Salvation-GoogleDoc')}
            >Google&nbsp;doc</a></span>
        </div>
      </div>

      <div className="book-body article-section" >

        <div className="article article-body">

          <div className="article-sub-title">Introduction</div>
          <p className="article-para">
            The Apostle Paul wrote Timothy, &ldquo;Preach the word. Be urgent in season and out of season... For the time is coming when people will not endure sound teaching... [they] will turn away from listening to the truth... As for you, always be steady. Endure suffering. Do the work of an evangelist. Fulfill your ministry.&rdquo; (2 Timothy 4:2-5)
          </p>
          <p className="article-para">
            Given pandemic fears, economic stress, and political strife, it might seem a bad time to be concerned about people&rsquo;s salvation and bringing them back to Jesus and his Church. But was there ever a good time or season for this? Was it a perfect time for the Apostles when they started to evangelize?
          </p>
          <p className="article-para">
            Despite being &ldquo;out of season,&rdquo; Cardinal George Pell wrote about <a href="https://www.firstthings.com/article/2020/12/last-things" target="_blank" rel="noopener noreferrer" >last things</a> in
          the journal First Things (December 2020). In that opinion piece, Pell mentioned how he came to believe &ldquo;nearly everyone would be saved.&rdquo; Then recently, his view changed in an &ldquo;unexpected way.&rdquo; A newly ordained American bishop, taking a preparation course in Rome, asked, if there is no punishment and all are saved, then why should we bother being priests? [And why should Catholics practice their faith?] This prompted Cardinal Pell to revisit Jesus&rsquo; teachings at which he realized there was insufficient support for his optimism. Cardinal Pell concluded, &ldquo;the failure to take seriously Jesus&rsquo; claim to judge everyone on the last day is at the heart of the crisis of faith and morals [i.e., the Church].&rdquo; As a result, people are in crisis.
          </p>
          <p className="article-para">
            There has been much online debate about the idea that it is very &ldquo;reasonable to hope everyone is saved&rdquo; as advocated, for instance, by Bishop Robert Barron. However, this optimistic view is challenged by some, like Professor Ralph Martin, who argue it is not reasonable given Jesus&rsquo; actual words and official Church teaching. This article seeks to dip below the theological debate and explore what is really underlying this &ldquo;reasonable hope&rdquo; and causing widespread presumption that everyone, or practically everyone, is saved despite the obvious meaning of Jesus&rsquo; words on hell, judgement and requirements for salvation. Then this piece will offer a more traditional view by reconsidering some of Jesus&rsquo; most dire teaching in light of God who is love, the crucifixion of his only beloved Son, and the intimate reality of heaven. Keep in mind that, fundamentally, this article is about believing all Jesus&rsquo; words, even his more difficult ones.
          </p>

          <div className="article-sub-title">An Underlying Feeling</div>
          <p className="article-para">
            Beneath reasonable hope and widespread optimism is a common feeling among the baptized, teachers and laity, that just about everyone is good enough and will eventually enter heaven. This feeling flows from a deep belief that God simply loves everyone regardless of their faults. Occasionally, when caused to think for a moment, this belief is supported by a quick intuitive calculation that if God is love, then he cannot deny salvation to people who are basically &ldquo;good in our eyes&rdquo; just because they did not live up to all Jesus&rsquo; words – for instance, those who are kind and generous, but did not care to know Jesus. Some of whom, incidentally, might be friends or loved ones who are dear to our own hearts. Consequently, the obvious meaning of Jesus&rsquo; dire words cannot be true. This is why people do not want to face all his words, and why most teachers of the faith will skip over or water them down. To put it another way, Jesus&rsquo; clear words about what is required for heaven are incongruous with what we feel is right and just.
          </p>
          <p className="article-para">
            But could this be a situation where we are &ldquo;thinking [and feeling] not as God does, but as human beings do&rdquo;? (Matthew 16:23b)
          </p>
          <p className="article-para">
            Now of course, God loves everyone infinitely more than the most perfect father on earth. The issue is, though, as with fallen angels, will people choose to love God?
          </p>

          <div className="article-sub-title">Jesus&rsquo; Difficult Words</div>
          <p className="article-para">
            Recall some of Jesus&rsquo; most difficult words. On the existence of hell, Jesus was clear. He referred to &ldquo;the eternal fire prepared for the devil and his angels.&rdquo; (Matthew 25:41) As to the permanence of damnation, Jesus called it &ldquo;eternal punishment&rdquo; (Matthew 25:46) and a place where the &ldquo;worm does not die.&rdquo; (Mark 9:48)
          </p>
          <p className="article-para">
            About the possibility of damnation, Jesus said &ldquo;do not fear those who kill the body but cannot kill the soul; rather fear him who can destroy both soul and body in hell.&rdquo; (Matthew 10:28) &ldquo;Enter by the narrow gate; for the gate is wide and the way is easy, that leads to destruction, and those who enter by it are many. For the gate is narrow and the way is hard, that leads to life, and those who find it are few.&rdquo; (Matthew 7:13-14)
          </p>
          <p className="article-para">
            As for who might not enter heaven, Jesus singled out the religious leaders of his faith who were hypocrites, cared more about their own position than the needs of their people, and misled followers by their example. (Matthew 23:1-33) During the last judgement scene, he said those who ignore people&rsquo;s basic needs, like food, water, clothing, and shelter, will be sent into the eternal fire prepared for the devil and his angels. (Matthew 25:41-43) He also listed things that defile a person and come out of his heart, e.g., evil thoughts, murder, adultery, fornication, theft, false witness, and slander. (Matthew 15:19-20a) Saint Paul used a similar but longer list, then added, &ldquo;I warn you, as I warned you before, that those who do such things will not inherit the kingdom of God.&rdquo; (Galatians 5:19-21)
          </p>
          <p className="article-para">
            Jesus spoke of other judgement scenes as well, where many are not let into heaven. For instance, &ldquo;many will come to the door and say, ‘Lord let us in.&rsquo; But I will say, ‘I do not know... you...&rsquo;&rdquo; (Luke 13:24-28) Or, a man gave a great dinner, but those he invited had various excuses and did not come. Jesus concluded, &ldquo;none of those who were invited will taste my dinner.&rdquo; (Luke 14:16-24) And for half the virgins who were not prepared and present when the Lord arrived, the door was locked. (Matthew 25:10-12)
          </p>
          <p className="article-para">
            Elsewhere, Jesus was very clear about what is required. &ldquo;Not everyone who says to me, ‘Lord, Lord,&rsquo; will enter the kingdom of heaven, but he who does the will of my Father who is in heaven.&rdquo; (Matthew 7:21) &ldquo;He who does not obey the Son will not see life, but the wrath of God is upon him.&rdquo; (John 3:36b) &ldquo;He who does not believe is condemned already.&rdquo; (John 3:18b) &ldquo;Unless you eat the flesh of the Son of Man and drink his blood, you have no life in you.&rdquo; (John 6:53)
          </p>

          <div className="article-sub-title">Visions &amp; Apparition at Fatima</div>
          <p className="article-para">
            Now, it has not only been Jesus, his disciples and official Church teaching that have warned us about the existence of hell and the reality of judgment, but we have been reminded throughout history by visions given to Saints and apparitions of our Heavenly Mother. Saints such as Catherine of Siena, Teresa of Avila, and John Bosco have had such visions. Saint Catherine, a doctor of the Church, described four ceaseless sufferings of hell: never being able to see God, profound regret, always able to see the ugliness of Satan, and burning in an eternal fire tailored to our sins.
          </p>
          <p className="article-para">
            A most striking account happened during the July apparition of Our Lady to the three children at Fatima. They were only 7, 9 and 10 years old when she showed them a vision of hell. Afterwards, Our Lady said, &ldquo;You have seen hell where the souls of poor sinners go. To save them God wishes to establish in the world devotion to my immaculate heart. If what I say to you is done, many souls will be saved and there will be peace.&rdquo; Then she taught them a prayer to be said after every decade of the Rosary, &ldquo;Oh my Jesus, forgive us our sins and save us from the fires of hell. Lead all souls to Heaven, especially those in most need of your mercy.&rdquo;  If our Holy Mother showed children hell, then shouldn&rsquo;t we pay attention?
          </p>
          <p className="article-para">
            Also surprising was some of our Lord&rsquo;s visions to Saint Faustina. She received revelations from Jesus, in Poland prior to World War II, which emphasized his merciful love. As a result of these private revelations, the Church celebrates the Sunday after Easter as Divine Mercy Sunday. But Faustina, too, was given a vision of hell. Then at the end of this vision she wrote, &ldquo;I am writing this at the command of God, so that no soul may find an excuse by saying there is no hell, or that nobody has ever been there and so no one can say what it is like.&rdquo; (Diary, 741)
          </p>
          <p className="article-para">
            In another vision, reminiscent of Jesus&rsquo; depiction of the broad way chosen by many that leads to destruction (Matthew 7:13-14), Saint Faustina of the Divine Mercy saw two roads. The broad one was covered with flowers and full of joy, music, and pleasures. Countless people walked on it, then at the end, fell into the abyss of hell. The other was a narrow path strewn with rocks and thorns. People on it suffered and had tears in their eyes. However, at the end, there was a magnificent garden full of happiness. Upon reaching the garden, they forgot all their suffering. (Diary, 153)
          </p>
          <p className="article-para">
            When we start applying these words to ourselves, and to others we know and love, these teachings are almost impossible to accept at a gut level. They are too terrifying, too permanent, and seem disproportionately unjust and unfair.
          </p>

          <div className="article-sub-title">A Traditional View </div>
          <p className="article-para">
            Nevertheless, though unsettling, consider a traditional view of salvation that is contrary to popular sentiment, accepts the accuracy of Jesus&rsquo; words, and is based on how much God loves us, the crucifixion of his only beloved Son, and the intimate reality of heaven.
          </p>
          <p className="article-para">
            &ldquo;For God so loved the world that he gave his only son...&rdquo; (John 3:16a) Consider how God the Father felt as he watched his son getting tortured and crucified for hours; and as He saw his son&rsquo;s mother, crushed by grief. Now, how does God the Father feel as many busy people neglect and forget Jesus?
          </p>
          <p className="article-para">
            Recall, too, what God has offered mankind. Jesus prayed, &ldquo;Father, as you are in me and I am in you, may they be one in us.&rdquo; (John 17:21) That is, heaven is intimate communion with the Holy Trinity, God in us and us in God, &ldquo;partakers of [His] divine nature.&rdquo; (2 Peter 1:4b) Consequently, how can people expect to jump into loving intimacy with God, having never spent time getting to know Him? Indeed, having no interest in Him and living their lives as if God did not exist, or Jesus was never born.
          </p>
          <p className="article-para">
            Remember, also, how Jesus summed up all the laws of God. He taught, &ldquo;You shall love the Lord your God with all your heart, and with all your soul, and with all your mind. This is the great and first commandment.&rdquo; (Matthew 22:37-38) But how can people be obeying the great and first commandment without having a personal relationship with Jesus and listening to his words? When they pass churches daily without going in? When seeing a crucifix or cross does not cause them to pause and reconsider? When they ignore that loving relative or friend who regularly practices his faith and never question him about it?
          </p>
          <p className="article-para">
            Moreover, when people do not know Jesus, and their moral conscience has not been formed by the Church, how frequently will they seriously offend God by believing what is bad is good, and what is good is bad? This sad plight is recognized by the Church. &ldquo;Very often, deceived by the Evil One, men have become vain in their reasonings, have exchanged the truth of God for a lie, and served the world rather than the Creator.&rdquo; (Lumen Gentium 16) What a frightening situation for the world as well as their salvation!
          </p>
          <p className="article-para">
            When God in a sense &ldquo;visits&rdquo; his people and provides opportunities to believe, and they fail to respond, punishment can be severe. Remember when Jesus sent out his Apostles to the lost sheep of Israel saying, &ldquo;if they will not listen to your words, shake the dust off your feet when you leave them. It will be more tolerable on the day of judgement for Sodom and Gomorrah than for that town.&rdquo; (Matthew 10:14-15) Or when Jesus said, &ldquo;O Jerusalem… how often would I have gathered your children together as a hen gathers her brood under her wings, and you would not!&rdquo; (Matthew 23:37) Then when Jesus drew near Jerusalem, he wept over her and lamented, &ldquo;for the days will come... when you and your children will be [destroyed] because you did not know the time of your visitation.&rdquo; (Luke 19:41-44) Finally, when Jesus commissioned his Apostles he said, &ldquo;Go into all the world and preach the gospel to the whole creation. He who believes and is baptized will be saved; but he who does not believe will be condemned.&rdquo; (Mark 16:15-16) In a variety of ways, God has visited his people and given them chances to reconsider, but they did not want a loving relationship with Him.
          </p>
          <p className="article-para">
            In &ldquo;<a href="https://www.firstthings.com/article/2008/02/001-who-can-be-saved-8" target="_blank" rel="noopener noreferrer" >Who Can Be Saved?</a>&rdquo; (First Things 2008),
          Jesuit Cardinal Avery Dulles wrote, &ldquo;Some, perhaps many, resist the grace of God and reject the signs given to them. They are not on the road to salvation at all. In such cases, the fault is not God&rsquo;s but theirs. The references to future punishment in the Gospels cannot be written off as empty threats. As Paul says, God is not mocked (Galatians 6:7).&rdquo; The Church teaches, &ldquo;If [Catholics] fail to respond in thought, word and deed to grace, not only will they not be saved, but they will be judged more severely.&rdquo; (Lumen Gentium 14)
          </p>
          <p className="article-para">
            On the critical possibility of losing salvation, many were lulled to sleep in recent decades, and have not woken up.
          </p>

          <div className="article-sub-title">On Culpability</div>
          <p className="article-para">
            Now one might ask, are there mitigating circumstances that can diminish culpability for those not practicing the faith? Of course, there are many, like having unconcerned preachers and teachers, scandals, being raised in difficult circumstances, and so forth. This is why only God can judge – &ldquo;Father, forgive them; for they know not what they do.&rdquo; (Luke 23:34)
          </p>
          <p className="article-para">
            Importantly, this uninformed situation can provide some hope when a loved one passes to God who has not known Jesus through little fault of his own. It is especially true when the deceased&rsquo;s loved ones are praying for God&rsquo;s mercy. The problem, however, is when nearly everyone presumes upon this mercy and fails to pray.
          </p>

          <div className="article-sub-title">Preachers &amp; Teachers</div>
          <p className="article-para">
            Another question is, why have so many preachers and teachers of the faith avoided, or explained away, Jesus&rsquo; dire teaching? First and foremost is probably the common human feeling that almost everyone is saved, which is supported by modern theologians. But there are other factors, too, such as a shortage of Catholic priests and the necessary time to hear individual confessions. Also, clergy are not free to speak plainly to their people, even if lovingly and carefully done, without risk of repercussion – people in the pews will not hesitate to complain to central office which can bring down chastisement from the bishop and his subordinates. In addition, life is much more pleasant if one can be kind all the time without having to upset people or engage in conflict. Likewise, paid lay teachers and ministries face similar circumstances, and risk being fired or never hired if they promote all God&rsquo;s words.
          </p>
          <p className="article-para">
            This brings to mind two examples. In his latest book &ldquo;A Church in Crisis – Pathways Forward,&rdquo; Professor Ralph Martin described what happened after he published &ldquo;A Crisis of Truth&rdquo; many years ago. Martin wrote, &ldquo;While there was strong support for the message, there was also strong opposition. Speaking invitations were mysteriously rescinded. Some people awkwardly told me that they would like me to deliver this message in their dioceses but that they couldn&rsquo;t invite me. In at least three dioceses that I know of, a blanket ban on my speaking was communicated to diocesan officials and lay leaders by the bishop.&rdquo;
          </p>
          <p className="article-para">
            In another case, an excellent Catholic teacher wrote a book on the topic of salvation. During his lecture series on it, he downplayed the issue of hell by lightheartedly referring to a view of salvation as &ldquo;fire insurance.&rdquo; Then, seventy percent into his lecture series, he started to talk about the reality of hell more seriously. At this point, however, he admitted that he came close to not discussing hell at all.
          </p>
          <p className="article-para">
            I cannot say what the teacher&rsquo;s ambivalence was concerning the topic of hell. But I will add that some preachers and teachers tactically believe it is more fruitful to keep things peaceful and friendly for their flock. However, if an urgent case for salvation is not periodically presented to those in the pews, they will never be motivated enough to pursue fallen away loved ones. As with the single talent, we cannot bury any of God&rsquo;s words. (MT 25:14-30) Recall the Lord to Ezekiel – if you do not warn the wicked man, his blood I will require at your hand. But if you do warn him, you will have saved your life. (Ezekiel 3:17-21, 33:6) Remember also how James warned that teachers will be judged more strictly. (James 3:1)
          </p>

          <div className="article-sub-title">Conclusion</div>
          <p className="article-para">
            In conclusion, the demise of the Church is caused by widespread disbelief in Jesus&rsquo; words and the related presumption everyone is saved. If we continue to fail to reach out and help people believe all God&rsquo;s words, then Jesus&rsquo; future concern might be prophetic – &ldquo;When the Son of Man returns will he find faith on earth?&rdquo; (Luke 18:8) Our rapidly shrinking Church desperately needs courageous ministers and followers who care about salvation and are willing to make sacrifices to help people understand, believe, and be ensured of eternal life.
          </p>

          <Share />

        </div>

        <div className="book-section0">
          <div className="book-section1 contact-info">Contact Information</div>
          <div className="book-description">
            Please contact me at:
            <br className="show-hide" /> <a href="mailto: DeaconJohnBeagan@gmail.com">DeaconJohnBeagan@gmail.com</a>
            <br className="show-hide" /> if you have any questions or feedback.
            <br />God bless!
            <div className="signature">
              Deacon John Beagan
            </div>
            <div className="serving">
              Serving Sacred Heart &amp; Saint Patrick parishes in Watertown, MA
            </div>
            <div className="serving">
              Archdiocese of Boston
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default Salvation;

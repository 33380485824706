import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Container, Image, Card } from 'react-bootstrap'
import {Helmet} from "react-helmet";
import './app.scss';
//import help from './images/storm-boat-web.jpg';
import help from './images/Help-Loved-Ones-Believe-White1b.jpg';
import Share from './ShareBelieve';
import itMakesSense from './images/It Makes Sense.jpg';
import passingTheFaith from './images/Passing the Faith.jpg';
import wordVideoScript from './docs/Video-Script.docx';
import pdfVideoScript from './docs/Video-Script.pdf';
import wordVideoNotes from './docs/Video-Homily-Notes.docx';
import pdfVideoNotes from './docs/Video-Homily-Notes.pdf';
import fromSomeone from './images/From Someone Who Loves You.jpg';


function OurLadyOfPerpetualHelp() {

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('G-P248LQSJST');
    }
  }, []);

  const gaTrack = (category, action) => {
    if (process.env.NODE_ENV === 'production') {
        ReactGA.event({ category, action })
    }
  }
  
  return (
    <>
      <Helmet>
        <title>Help Loved Ones Believe &#124; Intentionally Catholic</title>
      </Helmet>

      <Image src={help} className="img-fluid rounded" title="Help Loved Ones Believe" />

      <div className="book-body" >

        <div className="book-section0">
          <Card bg="light">
            <Card.Body style={{ paddingBottom: "0rem" }}>
              <Card.Text>
                <p className="ralph-quote">
                  Endorsed by <span style={{ fontWeight: "bold" }}>
                    Ralph Martin
                  </span>  whose latest book is <span style={{ fontStyle: "italic" }}>
                    A Church in Crisis: Pathways Forward
                  </span>.
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </div >

        <div className="book-section0" style={{ marginTop: "2rem", marginBottom: "3rem" }}>
          <div className="book-section1 book-the-book" >
            Doubts &amp; Disbelief
          </div>
          <div className="book-description">

          <p>As the deacon in town where I grew up and raised my children, I am troubled at
            Mass when I look out at the pews and see a growing number of empty seats. I know there
            are many Catholics in town who gradually fell away. And this was made worse by Covid. Consequently, 
            their children have no connection to the Church and their grandchildren will not be baptized. 
            They may never come to know Jesus.</p>

            <p>According to <span style={{ fontWeight: "bold" }}>Pew Research</span>, the biggest reason for people leaving the Catholic faith 
              is they "stopped believing." Moreover, according to a professional survey of those coming to Mass at my parish 
              a few years ago, only 1/6 "strongly believe" what the Church teaches on faith and morals &mdash; a  
              condition that seriously undermines parish enthusiasm. Meanwhile, others not 
              attending Mass simply feel it is not important or essential.</p>

            <p>Consequently, to reinvigorate our Church, 
              we must help those coming to fully believe the obvious meaning 
              of all God's words and invisible realities. Then, with their faith shored up, we can encourage them 
              to bring loved ones and friends back to Mass.
              </p>

              <p>However, if we do not intentionally help our people believe, then given the research,                  
                we will continue to shrink as a U.S. church despite all other best efforts.
              </p>

          </div>
        </div>

        <div className="book-section0" style={{ marginTop: "2rem", marginBottom: "3rem" }}>
          <div className="book-section1 book-it-makes-sense" >
            A Parish Campaign
          </div>
          <div className="book-section2 book-back-cover">
            <span style={{fontWeight: "bold"}}>&ldquo;It Makes Sense to Believe&rdquo;</span>
          </div>
          <div className="book-description">
            <p>To cure this infection of doubt and disbelief, we must present a case for Christ and His Church 
            to <span style={{fontStyle: "italic"}}>all</span> parishioners. This is a 
            simple orderly case, based on evidence and common sense, for believing as the Church does.  
            </p>
            <p>
            The best way to reach <span style={{fontStyle: "italic"}}>every</span> active Catholic is at Mass. Therefore, 
            over 12 weeks using free resources, email a video segment based on my case (endorsed by Ralph Martin) to all parishioners 
            to prepare for the coming weekend. Then at Mass, present the highlights of this segment, e.g., before the closing prayer, 
            at the end of a short homily, or woven into a homily. 
            </p>
            <p>            
            Spreading the case over several weeks will help it make a deeper impression 
            so our flock's faith might be more firmly planted. 
            </p>
            <p>
            See videos and suggested highlight scripts below. Consider launching 
            this <span style={{fontStyle: "italic"}}>mission</span> in 
            early January, on Divine Mercy Sunday, or in September.
            </p>
          </div>
          <div className="book-description">
            <Card bg="light">
              <Card.Body style={{ paddingBottom: "0rem" }}>
                <Card.Text>
                  <p className="ralph-quote"><span style={{ fontWeight: "bold" }}>&ldquo;</span>
                  Deacon John is a clear thinker and clear writer and has written a book
                  that will be a real contribution to Passing the Faith onto Loved Ones.
                  <span style={{ fontWeight: "bold" }}>&rdquo;</span>
                  </p>
                  <p><span style={{ fontWeight: "bold" }}>~ Ralph Martin, S.T.D.</span></p>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="book-section2">12 Video Segments</div>
          <div className="book-links">

            {/* 1 */}
            <a href="https://youtu.be/f9Kn_ZVVARI"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'believe-01-should-we')}
            >
              1-Should We Care?
            </a>
            <span className="book-span2"> (9&nbsp;min), </span>

            {/* 2 */}
            <a href="https://youtu.be/qAPaOiQOKHE"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'believe-02-truth')}
            >
              2-Importance of Truth
            </a>
            <span className="book-span2"> (3&nbsp;min), </span>

            {/* 3 */}
            <a href="https://youtu.be/egVKsIpmIqE"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'believe-03-is-god-dead')}
            >
              3-Is God Dead?
            </a>
            <span className="book-span2"> (3&nbsp;min), </span>

            {/* 4 */}
            <a href="https://youtu.be/m1BMtAtO23o"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'believe-04-reveal')}
            >
              4-Did God Reveal Himself?
            </a>
            <span className="book-span2"> (11&nbsp;min), </span>

            {/* 5 */}
            <a href="https://youtu.be/rrfp4G5s6mg"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'believe-05-Love')}
              style={{ fontWeight: "bold", fontStyle: "italic" }}
            >
              5-Love
            </a>
            <span className="book-span2"> (5&nbsp;min), </span>

            {/* 6 */}
            <a href="https://youtu.be/JHsuE3PYMq8"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'believe-06-evidence')}
            >
              6-Evidence
            </a>
            <span className="book-span2"> (4&nbsp;min), </span>

            {/* 7 */}
            <a href="https://youtu.be/JUr7l7o6vG8"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'believe-07-Evil')}
              style={{ fontWeight: "bold", fontStyle: "italic" }}
            >
              7-Evil
            </a>
            <span className="book-span2"> (20&nbsp;min), </span>

            {/* 8 */}
            <a href="https://youtu.be/0Fj5wAPt8UQ"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'believe-08-suffering-death')}
            >
              8-Suffering &amp; Death
            </a>
            <span className="book-span2"> (9&nbsp;min), </span>

            {/* 9 */}
            <a href="https://youtu.be/16lbIYsre3Y"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'believe-09-little-faith')}
            >
              9-A Little Faith Is Needed
            </a>
            <span className="book-span2"> (8&nbsp;min), </span>

            {/* 10 */}
            <a href="https://youtu.be/8wDDdkTDI8M"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'believe-10-sex')}
            >
              10-Sex, Sin &amp; Scandal
            </a>
            <span className="book-span2"> (7&nbsp;min), </span>

            {/* 11 */}
            <a href="https://youtu.be/BCjCYcULNMc"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'believe-11-women')}
            >
              11-Women's Ordination
            </a>
            <span className="book-span2"> (5&nbsp;min), </span>

            {/* 12 */}
            <a href="https://youtu.be/C84uxYDg9mU"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'believe-12-making-it')}
            >
              12-Making It into Heaven
            </a>
            <span className="book-span2"> (9&nbsp;min) </span>

          </div>

          <div className="book-section2">Segment Highlights for Mass</div>
          <div className="book-description">
            <p>Ideally, the preacher will have read the short book and watched the video segment. But 
            to save time, I prepared these highlights. Each segment takes about 2 to 4 minutes to read. 
            Please note, the second and third segments were combined into one week because they're short.</p>
          </div>
          <div className="book-links">
            <span className="book-link" >
              <a href={wordVideoNotes} onClick={() => gaTrack('Resources', 'Believe-VideoNotes-Word')}
              >Word doc</a></span>
            <span className="book-link" >
              <a href={pdfVideoNotes}
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'Believe-VideoNotes-Pdf')}
              >Pdf</a></span>
            <span><a href="https://docs.google.com/document/d/1nfiILvEhEmuiBHq5WNdDT5dTgxOlk6ek_bUuStP0qzg/edit?usp=sharing"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'Believe-VideoNotes-GoogleDoc')}
            >Google doc</a></span>
          </div>

          <div className="book-section2">Video Script</div>
          <div className="book-description">
            The video script is available for download, e.g., for copy/paste.
            Also, book manuscripts are likewise freely available on book websites
            (see <a href="https://www.IntentionallyCatholic.com"
            target="_blank" rel="noopener noreferrer"
            onClick={() => gaTrack('Resources', 'believe-script-IntentionallyCatholicLink-1')}
            style={{ fontStyle: "italic", fontWeight: "400" }}>
            main page
          </a>). 
          </div>
          <div className="book-links">
            <span className="book-link" >
              <a href={wordVideoScript} onClick={() => gaTrack('Resources', 'believe-VideoScript-Word')}
              >Word doc</a></span>
            <span className="book-link" >
              <a href={pdfVideoScript}
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'believe-VideoScript-Pdf')}
              >Pdf</a></span>
            <span><a href="https://docs.google.com/document/d/16f7uI9gGi50tByeVNxjoINBFInijh_bPigp2MwY3S7w/edit?usp=sharing"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'Believe-VideoScript-GoogleDoc')}
            >Google doc</a></span>
          </div>
        </div>

        <div className="book-section0" style={{ marginBottom: "4rem" }}>
          <div className="book-section1 book-passing-the-faith">For Those You Love</div>
          <div className="book-description">
            This video is an invitation to send to fallen-away loved ones 
            so they might consider the books' simple case for the faith 
            and come to Mass. 
          </div>
          <div className="book-links">
            <a href="https://youtu.be/GQb7dBQy6lM"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'FromSomeone-Believe')}
              >
              <Image src={fromSomeone} 
                    className="img-fluid rounded" 
                    title="From Someone Who Loves You"
                    style={{border: "1px solid #DCDCDC"}}
                    />
              </a>
            <div className="book-span">(11 minutes)</div>
          </div>
        </div>

        <div className="book-section0">
          <div className="book-section1 contact-info" style={{ marginTop: "4rem" }}>
            The Books
          </div>
          <div className="book-description">
            <p>The book comes in two flavors; only 3 chapters 
            differ. <span style={{ fontWeight: "bold" }}>It Makes Sense to Believe</span> was tailored for 
            those who disbelieve or are 
            lukewarm. <span style={{ fontWeight: "bold" }}>Passing the Faith onto Loved Ones</span> is meant 
            for those who love the Church. 
            Ralph Martin read and endorsed this one. Each book contains the same case to help 
            people fully believe in God, His Beloved Son Jesus, and the Church he historically established.
            </p>
            <p>
            Also &mdash; book narrations, manuscripts, and videos, are freely available on the books' websites
            For links to these sites go  
            to <a href="https://www.IntentionallyCatholic.com"
            target="_blank" rel="noopener noreferrer"
            onClick={() => gaTrack('Resources', 'believe-books-IntentionallyCatholicLink-2')}
            style={{ fontStyle: "italic", fontWeight: "400" }}>
            main page
            </a>.
            Paperback and Kindle versions are inexpensively available on Amazon; and U.S. bulk orders can be arranged.
          </p> 
          </div>
        </div>


        <div className="book-section0" style={{ marginBottom: "2rem" }}>
          <div className="book-section1 book-it-makes-sense" style={{ fontStyle: "italic" }}>
            It Makes Sense to Believe
          </div>
          <div id="" className="subsection">
            <div id="" className="book-section2" >
              For those who doubt or disbelieve. Or are lukewarm and disinterested.
            </div>
            <div id="" className="book-description" >
              Ideal for confirmation students &mdash; before they go to college! And it
              can be taught in one or two sessions. Likewise, for the parents of students
              in faith formation since they are the primary educators. Finally, it can be 
              made available to all parishioners.
            </div>
            <div className="book-cover" >
              <a href="https://www.amazon.com/dp/B08BWFKBDK?ref_=pe_3052080_397514860"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'BelieveItMakesSenseCover')}
                style={{ textDecoration: "none" }}>
                <Image src={itMakesSense}
                  className="img-fluid rounded"
                  title="It Makes Sense to Believe"
                />
              </a>
            </div>

            <div className="book-button-outer" >
              <div className="book-links">
                <a href="https://www.amazon.com/dp/B08BWFKBDK?ref_=pe_3052080_397514860"
                  target="_blank" rel="noopener noreferrer"
                  onClick={() => gaTrack('Resources', 'BelieveItMakesSenseButton')}
                  style={{ textDecoration: "none" }}>
                  <span
                    className="book-button"
                    style={{ backgroundColor: "#088c9b" }}
                  >
                    Amazon Books
                </span>
                </a>
                <div className="book-price">(Kindle 4.95 US, Paperback 7.95 US)</div>
              </div>
            </div>

          </div>
        </div>


        <div className="book-section0" style={{ marginBottom: "2rem" }}>
          <div className="book-section1 book-passing-the-faith" style={{ fontStyle: "italic" }}>
            Passing the Faith onto Loved Ones
          </div>
          <div id="" className="subsection">
            <div id="" className="book-section2" >
              For those who love the Church.
            </div>
            <div id="" className="book-description" >
              Best for teachers, ministers, staff, and other enthusiastic laypeople. Good
              for anyone who wants to pass the faith on to a loved one.
            </div>
            <div className="book-cover" >
              <a href="https://www.amazon.com/dp/1659148014?ref_=pe_3052080_397514860"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'BelievePassingTheFaithCover')}
                style={{ textDecoration: "none" }}>
                <Image src={passingTheFaith}
                  className="img-fluid rounded"
                  title="Passing the Faith onto Loved Ones"
                />
              </a>
            </div>

            <div className="book-button-outer" >
              <div className="book-links">
                <a href="https://www.amazon.com/dp/1659148014?ref_=pe_3052080_397514860"
                  target="_blank" rel="noopener noreferrer"
                  onClick={() => gaTrack('Resources', 'BelievePassingTheFaithButton')}
                  style={{ textDecoration: "none" }}>
                  <span
                    className="book-button"
                    style={{ backgroundColor: "#ca8e4c" }}
                  >
                    Amazon Books
                </span>
                </a>
                <div className="book-price">(Kindle 4.95 US, Paperback 7.95 US)</div>
              </div>
            </div>

          </div>
        </div>

        <div className="book-section0 margin-top" style={{marginBottom: "6rem"}}>
          <div className="book-section1 salvation-crisis" >
            Salvation: Church in Crisis
          </div>
          <div className="book-description">
            This article refers to a December 2020 column by 
            Cardinal George Pell and to an earlier one by Cardinal 
            Avery Dulles. It explores what underlies the widespread 
            presumption that almost everyone makes it to heaven 
            despite the obvious meaning of Jesus' words. Then it reconsiders 
            Jesus' difficult teaching in light of God who is love, the 
            crucifixion of his only beloved son, and the intimate reality of heaven.
            The article can be read, downloaded or watched on 
            YouTube.
          </div>
          <div className="book-button-outer" >
            <div className="book-links">
              <a href="https://www.IntentionallyCatholic.com/Salvation"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'BelieveGotoSalvation')}
                style={{ textDecoration: "none" }}>
                <span
                  className="book-button salvation-crisis"
                  style={{ backgroundColor: "#9b0000" }}
                >
                  Goto Salvation
              </span>
              </a>
            </div>
          </div>
      </div>

      <div className="book-section0" style={{ marginBottom: "6rem" }}>
        <div className="book-section1" style={{color: "#228B22"}} >
          Intentionally Catholic
        </div>
        <div className="book-description">
          Resources for clergy and laity to shore up the faith 
          of parishioners and help pass the faith on to loved ones.
        </div>
        <div className="book-button-outer" >
          <div className="book-links">
            <a href="https://www.IntentionallyCatholic.com"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'Intentionally-Believe')}
              style={{ textDecoration: "none" }}>
              <span
                className="book-button"
                style={{ backgroundColor: "#228B22" }}
              >
                Main Page
              </span>
            </a>
          </div>
        </div>
      </div>
          
        <Share />


        <div className="book-section0">
          <div className="book-section1 contact-info" style={{ marginTop: "4rem" }}>
            Contact Information</div>
          <div className="book-description">
            <div >
              <span style={{ color: "red" }}>U.S. bulk orders can be arranged. Contact me.
              </span> <span style={{ fontStyle: "italic" }}>Also -- videos, narrations, and manuscripts are freely available on the books'
              websites. Go to <a href="https://www.IntentionallyCatholic.com"
                  target="_blank" rel="noopener noreferrer"
                  onClick={() => gaTrack('Resources', 'BelieveIntentionallyCatholicLink')}
                  style={{ fontStyle: "italic", fontWeight: "400" }}>
                  IntentionallyCatholic.com
                </a>.
              </span>
            </div>
          </div>
          <div className="book-description">
            Please contact me at:
            <br className="show-hide" /> <a href="mailto: DeaconJohnBeagan@gmail.com">DeaconJohnBeagan@gmail.com</a>
            <br className="show-hide" /> if you have any questions or feedback.
            <br />God bless!
            <div className="signature">
              Deacon John Beagan
            </div>
            <div className="serving">
              Serving Sacred Heart &amp; Saint Patrick parishes in Watertown, MA
            </div>
            <div className="serving">
              Archdiocese of Boston
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default OurLadyOfPerpetualHelp;

import React from 'react';
import './share.scss';
import {TwitterShareButton, TwitterIcon, EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon } from 'react-share';

function ShareGeneric(props)  {
  /*
      props.url, e.g., https://intentionallycatholic.com/Army
      props.title
      props.subject
      props.body
      props.caption, e.g., Share this article with those who care.
  */

  return (
    <div className="share-container">
    <FacebookShareButton className="share-venue" url={props.url} >
      <FacebookIcon size={"2.5rem"} borderRadius={"8px"} />
    </FacebookShareButton>
    <TwitterShareButton className="share-venue" url={props.url} title={props.title}>
      <TwitterIcon size={"2.5rem"} borderRadius={"8px"} />
    </TwitterShareButton>
    <EmailShareButton  className="share-venue" 
      url={props.url}
      subject={props.subject}
      body={props.body}
    >
      <EmailIcon size={"2.5rem"} borderRadius={"8px"} />
    </EmailShareButton>
    <span className="show-hide"><br /></span><span style={{fontStyle: "italic", fontSize: "1.2rem"}}>{props.caption}</span>
  </div >
  )

}

export default ShareGeneric;

import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import {Helmet} from "react-helmet";
import { Image } from 'react-bootstrap'
import './app.scss';
import crisis from './images/golf-crisis-web.jpg';
import Share from './ShareCrisis';
import crisisYouTube from './images/golf-youtube.jpg';
import youtube from './images/youtube_icon.png';
import fromSomeone from './images/From Someone Who Loves You.jpg';
import itMakesSense from './images/It Makes Sense.jpg';

function Crisis() {

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('G-P248LQSJST');
    }
  }, []);

  const gaTrack = (category, action) => {
    if (process.env.NODE_ENV === 'production') {
        ReactGA.event({ category, action })
    }
  }

  return (
    <>
      <Helmet>
        <title>Crisis &#124; Intentionally Catholic</title>
      </Helmet>
      <Image src={crisis} className="img-fluid rounded" title="Disturbing Images" />

      <div className="book-body" >


        <div className="book-section0" style={{ marginTop: "2rem", marginBottom: "3rem" }}>
          <div className="book-section1 salvation-crisis" >
            Two Disturbing Images
          </div>
          <div className="book-description">
            <div>
              As a deacon in town where I grew up and raised my children, I am uncomfortable
              watching families and generations drift away from church and forget Jesus. 
              Two images express why.
          </div>

            <div style={{ marginTop: "1rem", marginBottom: "0rem" }}>
              <a href="https://youtu.be/BbPnNn_a6hc"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'Crisis-Video')}
                style={{ fontStyle: "italic" }}
              >
                <Image src={youtube}
                  className="img-fluid rounded"
                  title="Video: Two Disturbing Images"
                  width="81px"
                  style={{ marginRight: "0.5rem" }}
                />
                (Watch)
              </a>
              <div className="book-span">(2 min)</div>
            </div>
          </div>
        </div>

        <div className="book-section0" style={{marginTop: "2rem", marginBottom: "5rem"}}>
          <div className="book-section1 book-passing-the-faith" >
            Salvation: Church in Crisis
          </div>
          <div className="book-description">
            This article refers to a recent column (December 2020) by 
            Cardinal George Pell and to an earlier one by Cardinal 
            Avery Dulles. It explores what underlies the widespread 
            presumption that almost everyone makes it to heaven 
            despite the obvious meaning of Jesus' words. Then it reconsiders 
            Jesus' difficult teaching in light of God who is love, the 
            crucifixion of his only beloved son, and the intimate reality of heaven.
            The article can be read, downloaded, or watched on 
            YouTube. 
          </div>
          <div className="book-button-outer" >
              <div className="book-links">
                <a href="https://www.IntentionallyCatholic.com/Salvation"
                  target="_blank" rel="noopener noreferrer"
                  onClick={() => gaTrack('Resources', 'GotoSalvation-Crisis')}
                  style={{ textDecoration: "none" }}>
                  <span
                    className="book-button book-passing-the-faith"
                    style={{ backgroundColor: "#ca8e4c" }}
                  >
                    Goto Salvation
                </span>
                </a>
              </div>
            </div>
        </div>

        <div className="book-section0" style={{ marginBottom: "3rem" }}>
          <div className="book-section1 book-back-cover">For Those You Love</div>
          <div className="book-description">
            This video is an invitation to send to fallen-away loved ones 
            so they might consider the book's simple case for the faith 
            and come to Mass. 
          </div>

          <div className="book-links">
            <a href="https://youtu.be/GQb7dBQy6lM"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'FromSomeone-Crisis')}
              >
              <Image src={fromSomeone} 
                    className="img-fluid rounded" 
                    title="From Someone Who Loves You"
                    style={{border: "1px solid #DCDCDC"}}
                    />
              </a>
            <div className="book-span">(11 minutes)</div>
          </div>
        </div>

        <div className="book-section0" style={{marginTop: "2rem"}}>
          <div className="book-section1 book-the-video" style={{ fontStyle: "italic" }}>
            It Makes Sense to Believe
          </div>
          <div id="" className="subsection">
            <div id="" className="book-section2" >
              For those who doubt or disbelieve...
            </div>
            <div id="" className="book-section2b" >
              or are lukewarm and disinterested...
            </div>
            <div className="book-cover" >
              <a href="https://www.amazon.com/dp/B08BWFKBDK?ref_=pe_3052080_397514860"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'ForThose-CoverClickItMakesSense')}
                style={{ textDecoration: "none" }}>
                <Image src={itMakesSense}
                  className="img-fluid rounded"
                  title="It Makes Sense to Believe"
                />
              </a>
            </div>
            <div className="book-button-outer" >
              <div className="book-links">
                <a href="https://www.itmakessensetobelieve.com"
                  target="_blank" rel="noopener noreferrer"
                  onClick={() => gaTrack('Resources', 'ForThose-SiteLinkItMakesSense')}
                  style={{ textDecoration: "none" }}>
                  <span
                    className="book-button"
                    style={{ backgroundColor: "#d12233" }}
                  >
                    Goto Book Website
                </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="book-section0" style={{ marginBottom: "6rem" }}>
          <div className="book-section1 book-it-makes-sense" >
            Intentionally Catholic
          </div>
          <div className="book-description">
            Resources for clergy and laity to shore up the faith 
            of parishioners and help pass the faith on to loved ones.
          </div>
          <div className="book-button-outer" >
            <div className="book-links">
              <a href="https://www.IntentionallyCatholic.com"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'Intentionally-Crisis')}
                style={{ textDecoration: "none" }}>
                <span
                  className="book-button"
                  style={{ backgroundColor: "#088c9b" }}
                >
                  Goto Main Page
                </span>
              </a>
            </div>
          </div>
        </div>


        <Share />


        <div className="book-section0">
          <div className="book-section1 contact-info">Contact Information</div>
          <div className="book-description">
            Please contact me at:
            <br className="show-hide" /> <a href="mailto: DeaconJohnBeagan@gmail.com">DeaconJohnBeagan@gmail.com</a>
            <br className="show-hide" /> if you have any questions or feedback.
            <br />God bless!
            <div className="signature">
              Deacon John Beagan
            </div>
            <div className="serving">
              Serving Sacred Heart &amp; Saint Patrick parishes in Watertown, MA
            </div>
            <div className="serving">
              Archdiocese of Boston
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default Crisis;

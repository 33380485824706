import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Container, Image, Card } from 'react-bootstrap'
import {Helmet} from "react-helmet";
import './app.scss';
import salvation from './images/army.jpg';
import Share from './ShareGeneric';
import word from './docs/Army in the Pews.docx';
import pdf from './docs/Army in the Pews.pdf';
import youtube from './images/youtube_icon.png';


function Army() {

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('G-P248LQSJST');
    }
  }, []);

  const gaTrack = (category, action) => {
    if (process.env.NODE_ENV === 'production') {
        ReactGA.event({ category, action })
    }
  }
  return (
    <>
      <Helmet>
        {/* 
            To get SEO to work for other pages, e.g., salvation, 
            must serve index.html page content from node server, e.g., a lambda,
            substituting og meta tags. This should work if direct link,
            but maybe not if react router. Though not sure latter matters.
            Too bad services don't wait for JS to alter meta tags.
        */}
        <title>Army in the Pews &#124; Intentionally Catholic</title>
      </Helmet>
      <Image src={salvation} className="img-fluid rounded" title="Army in the Pews" />
      <header>
        <div className="text-center article article-title" >
          Army in the Pews
        </div>
        <div className="text-center article article-by" >
          May 2022
        </div>
      </header>
      <div className="text-center">
        <div style={{ fontSize: "1.1rem", marginTop: "1rem", marginBottom: "0rem" }}>
          <a href="https://youtu.be/lrQd-6nXW2Q"
            target="_blank" rel="noopener noreferrer"
            onClick={() => gaTrack('Resources', 'Army-YouTube')}
            style={{ fontStyle: "italic" }}
          >
          <Image src={youtube}
              className="img-fluid rounded"
              title="Video: Army in the Pews"
              width="32px"
              style={{marginRight: "0.5rem"}}
            />
            (Listen)
          </a>
          </div>
      </div>
      <div className="text-center">
        <div style={{ fontSize: "1.1rem", marginTop: "1rem", marginBottom: "0rem" }}>
          <span className="article-link-span" style={{ fontWeight: "bold" }}>Download: </span>
          <span className="article-link-span" >
            <a href={word} onClick={() => gaTrack('Resources', 'Army-Word')}
            >Word&nbsp;doc</a></span>
          <span className="article-link-span" >
            <a href={pdf}
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'Army-Pdf')}
            >Pdf</a></span>
          <span className="article-link-span">
            <a href="https://docs.google.com/document/d/1VxuKkn5n6OoKxEMQ5ssNZcAitxpitMJReOJ6UzkFIVI/edit?usp=sharing"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'Army-GoogleDoc')}
            >Google&nbsp;doc</a></span>
        </div>
      </div>

      <div className="book-body article-section" >

        <div className="article article-body">
          <p className="article-para" />

          <p style={{ fontStyle: "italic", fontSize: "125%", marginBottom: "1.5rem", marginTop: "1.5rem" }} >
            An important story about a passing opportunity to bring fallen-away Catholics back to church.
          </p>

          <p className="article-para">
          Every week during World War II, many thousands of Catholics flocked to Mission Church (Our Lady of Perpetual Help) in Boston to pray for loved ones and an end to the war. Now suppose the Russian invasion of Ukraine escalates enough to pull NATO into the fight, then causes the use of tactical nuclear weapons, which finally triggers the launch of strategic nuclear weapons, how many baptized Catholics will know enough to go to church, fall on their knees, and pray for God&rsquo;s intervention as global catastrophe mushrooms? Probably not many when compared to the old days given the decline in Mass attendance since the last world war. Even so, recognizing our Church&rsquo;s deteriorating condition in the U.S., and assuming the Lord gives us more time, can we reverse the downward spiral and bring many baptized Catholics back?
          </p>

          <p className="article-para">
          There is one word that comes up in Catholic conversation which irritates me every time I hear it. And the word is &ldquo;remnant.&rdquo; It frustrates me even though it might very well become true. In fact, many Catholic leaders already seem resigned to becoming a numerically small church. However, this does not have to be our destiny.
          </p>

          <p className="article-para">
          Every weekend I see hundreds of healthy confirmed Catholics at Mass. Multiply this by thousands of U.S. parishes and we have a large army of let&rsquo;s say two million able bodied Catholics, albeit mostly sitting on their bottoms waiting to be roused and led. I don&rsquo;t think I will ever be able to settle for a remnant, when so many Catholics are on the bench.
          </p>

          <p className="article-para">
          That said, however, given my recent mission experience, and without a change in tactics, U.S. Catholics will become a mere remnant. This will happen as my baby-boom generation and older Gen-X&rsquo;s pass to the Lord in the next 30 years, and as many Latin American immigrants are assimilated and deceived by our progressive and materialistic culture&rsquo;s empty promises. Consequently, caring courageous Catholics must act now to combat our decline and bring the fallen-away home.
          </p>

          <p className="article-para">
          The roots of my mission started growing decades ago upon discovering EWTN, Scot Hahn, and the new Catechism. At that time, I was compelled to revisit my faith by the horrific reality of partial birth abortion, and &ldquo;good Catholic&rdquo; legislators – friends of mine – who supported abortion without restrictions. Then, as I studied my faith in earnest, I was surprised to notice how much sense God&rsquo;s words made. They all fit together like pieces in a puzzle or notes in a symphony. That is, it made sense to believe as our Church officially does! Excited by this, I tried year after year to share my understanding with teenagers in faith formation, using video, etc. After a lack of success, my attention turned to their parents and grownups, and I entered diaconate formation.
          </p>

          <p className="article-para">
          Later, as a deacon at Mass, I watched spaces growing in the pews and became increasingly concerned about Catholics drifting away, neglecting and forgetting Jesus, and failing to pass the faith on to children and grandchildren. Will their babies ever be baptized? And, for those who fell away, what will that moment be like when it is time to stand before God, the Father of Jesus crucified? So, I started monitoring contemporary ideas in evangelization from Rebuilt to Bishop Barron. Eventually, I saw two largely unaddressed problems.
          </p>

          <p className="article-para">
          First, according to Pew Research, &ldquo;<a href="https://www.pewresearch.org/religion/2009/04/27/faith-in-flux3/" target="_blank" rel="noopener noreferrer" >Leaving Catholicism</a>&rdquo; (2009), 65% became unaffiliated because they &ldquo;stopped believing.&rdquo; Furthermore, a professional study in my parish (before Covid) revealed that only 1/6 of our active parishioners &ldquo;strongly believe&rdquo; what the Church teaches on faith and morals – a situation that severely undermines enthusiasm. Evidence such as this firmly indicated that the overarching problem in our Church is primarily a crisis of faith. Of course, this was confirmed in a 2019 <a href="https://www.pewresearch.org/fact-tank/2019/08/05/transubstantiation-eucharist-u-s-catholics/" target="_blank" rel="noopener noreferrer" >Pew report</a> that showed 1/3 of those regularly going to Mass did not believe in the real presence of Jesus.
          </p>

          <p className="article-para">
          The other largely ignored problem was an apparent disregard for the obvious meaning of Jesus&rsquo; words concerning requirements for salvation and that &ldquo;many&rdquo; might not make it to heaven. In the <a href="https://www.firstthings.com/article/2020/12/last-things" target="_blank" rel="noopener noreferrer" >December 2020</a> issue of <span style={{ fontStyle: "italic" }}>First Things</span>, Cardinal George Pell explained how he revisited Jesus&rsquo; words and realized there was insufficient support for his optimism that nearly everyone would be saved. Then he concluded, &ldquo;the failure to take seriously Jesus&rsquo; claim to judge everyone on the last day is at the heart of the crisis of faith and morals.&rdquo;
          </p>

          <p className="article-para">
          To address this lack of belief and lethargy, I created a long multi-media presentation, two 90-minute sessions, entitled, &ldquo;We Can Believe;&rdquo; put them on YouTube; and sent flyers to 40 nearby parishes (pastors and faith formation directors) offering free presentations and discussion. Only one pastor, who was a friend of mine, accepted the offer. My brother, who did not practice the faith, came to both sessions, and took it to heart. Later, I turned the script into a short book endorsed by Ralph Martin and made the video more concise.
          </p>

          <p className="article-para">
          I was convinced, if we do not focus and intentionally help people in the pews to &ldquo;strongly believe&rdquo; all God&rsquo;s words and invisible realities, then we will continue to shrink until we become a very small remnant. This will happen despite all other best efforts because most people are naturally preoccupied with things they want and must do, and do not believe church is necessary.
          </p>

          <p className="article-para">
          To put it another way, if we cannot rely on God&rsquo;s words, and everyone goes to heaven, then why practice the faith? Accordingly, attending Mass and receiving the Sacraments are not essential. It is like, to use a battlefield image, Catholic souls continuing to be struck down by machinegun crossfire from the Hill of Disbelief and the Hill of Everyone Is Saved.
          </p>

          <p className="article-para">
          Incidentally, it is dubious that after 2000 years of worrying about people&rsquo;s salvation, we finally got it right in my lifetime – that everyone likely goes to heaven, and hell is probably or almost empty – despite clear warnings from Jesus and his disciples about judgement and what is required for eternal life. For instance, how can someone expect to enter <span style={{ fontStyle: "italic" }}>intimate</span> communion with God having never spent time getting to know him, while having every opportunity to do so?
          </p>

          <p className="article-para">
          Consequently, the body of my material is a brief simple case, based on evidence and common sense, for believing in God, his only beloved son Jesus, and the Church he historically established. It also deals with controversial issues, such as scandals and sexual morality, and our need for salvation. Everything is freely available online. (See <a href="https://intentionallycatholic.com/" target="_blank" rel="noopener noreferrer" >IntentionallyCatholic.com</a>.)          
          </p>

          <p className="article-para">
          I entitled this mission, &ldquo;Help Loved Ones Believe.&rdquo; To test for interest, I contacted a thousand bishops, pastors, and priests across the country citing Ralph Martin&rsquo;s endorsement. I also wrote to a number of faithful Catholic ministries. Unfortunately, there were only some flickers of interest, like tossing lit candles in the night onto a dark pond. My goal was to find a couple of pastors or a bishop to try out this mission, shore up the faith of people in the pews, and inspire them to have conversations with loved ones and bring them back to church. Apparently, however, &ldquo;helping people believe&rdquo; and my concern for salvation did not resonate.
          </p>

          <p className="article-para">
          Now, you might be thinking, &ldquo;Oh, deacon. Your material must be terrible.&rdquo; Granted, it&rsquo;s not Word on Fire quality, but it&rsquo;s also not that bad. Ralph Martin, who does not know me, said in an email after reviewing my book that he was &ldquo;impressed with the clarity and soundness&rdquo; of my writing. Others also weighed in on Amazon. One fellow from a Catholic men&rsquo;s group wrote me a long thank you. And after my pastoral council handed out the book a year ago, various people came up to me later to say how much they liked it, such as a schoolteacher and an old Italian laborer.
          </p>

          <p className="article-para">
          But getting a few people to read the book was not my aim. After all, if one men&rsquo;s group member reads it or twenty parishioners do, what is that going to change? I figured the best place to shore up the faith of the greatest number of parishioners was at Mass. (Where else do we have the attention of all active Catholics?) So, I wrote letters and sent post cards to pastors and bishops, as mentioned above, recommending that my video, divided into twelve short segments, be sent out before the coming Sunday, one each week. Then use approximately 3 minutes before the closing prayer or during the homily to highlight this part of the case for believing. Spreading the case over twelve weeks could loosen the ground in many souls for receiving God&rsquo;s words. Moreover, it could make the faithful feel more confident in encouraging loved ones to return to church — because it&rsquo;s true and 
          essential. (See <a href="https://intentionallycatholic.com/believe" target="_blank" rel="noopener noreferrer" >IntentionallyCatholic.com/Believe</a>.)
          </p>

          <p className="article-para">
          Bishops and pastors were in the perfect position to rally their army in the pews and send them out to capture the hills of disbelief, presumption, unchastity, &ldquo;many ladders to heaven,&rdquo; and all other enemy territory. Regrettably, even though we&rsquo;re on a definite path of demise, they continued to stay the course – like the steering wheel is stuck and brakes don&rsquo;t work.            
          </p>

          <p className="article-para">
          It is unconscionable, for instance, that our confirmed teenagers and Catholic high school graduates will be sent into an increasingly secular and darker world without a clear simple case for why it makes sense to believe as a Catholic. Furthermore, the parents of children in religious education, some of whom do not come to Mass, need to understand a case like this, too. In fact, our primary focus should be helping parents grow in knowledge and love of God. After all, they are in the best position to pass the faith on to their children and make sure they keep it during adulthood.            
          </p>

          <p className="article-para">
          After reaching out to so many priests, bishops, and ministries, I am bewildered by their apparent lack of concern for salvation, and satisfaction with the flock they have while so many Catholics are falling away – losing six for every one convert (Pew – <a href="https://www.pewresearch.org/religion/2015/05/12/americas-changing-religious-landscape/" target="_blank" rel="noopener noreferrer" >Landscape 2015</a>), but probably worse after Covid. How can we simply carry on as if doubt and disbelief do not have to be addressed?
          </p>

          <p className="article-para">
          Church leadership is missing a grand opportunity by not rousing an army in the pews. Consequently, the faithful cannot wait for leadership to change course, preach the fullness of truth, and help people believe the obvious meaning of all God&rsquo;s words and invisible realities. Rather, each of us must spread the word, reach out to loved ones, show them how much sense our faith makes, and bring them back to church. That is, we must take salvation seriously, for this life and the next. Because if <span style={{ fontStyle: "italic" }}>we</span> do not care for our relatives and friends, then besides God, who will?
          </p>

          <p className="article-para" style={{ fontStyle: "italic" }}>
          This is the first in a trilogy of articles. The second is, &ldquo;<a href="https://intentionallycatholic.com/dire" target="_blank" rel="noopener noreferrer" >An Intuitive Case for Jesus&rsquo; Dire Words</a>&rdquo; which is based on how much God loves us, the crucifixion of is only beloved Son, and the intimate reality of heaven. Finally, the third article is, &ldquo;<a href="https://intentionallycatholic.com/share" target="_blank" rel="noopener noreferrer" >Sharing with Loved Ones the Reasons for Our Hope</a>,&rdquo; to help us initiate conversations and bring Catholics back.
          </p>

          <Share 
            url = "https://IntentionallyCatholic.com/Army"
            title = "Army in the Pews"
            subject = "Army in the Pews"
            body = ""
            caption = "Share this article with those who care."        
          />

        </div>

        <div className="book-section0">
          <div className="book-section1 contact-info">Contact Information</div>
          <div className="book-description">
            Please contact me at:
            <br className="show-hide" /> <a href="mailto: DeaconJohnBeagan@gmail.com">DeaconJohnBeagan@gmail.com</a>
            <br className="show-hide" /> if you have any questions or feedback.
            <br />God bless!
            <div className="signature">
              Deacon John Beagan
            </div>
            <div className="serving">
              Serving Sacred Heart &amp; Saint Patrick parishes in Watertown, MA
            </div>
            <div className="serving">
              Archdiocese of Boston
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default Army;

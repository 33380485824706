import React from 'react';
import './share.scss';
import {TwitterShareButton, TwitterIcon, EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon } from 'react-share';

function ShareSalvation()  {

  return (
    <div className="share-container">
    <FacebookShareButton className="share-venue" url="https://intentionallycatholic.com/Salvation" >
      <FacebookIcon size={"2.5rem"} borderRadius={"8px"} />
    </FacebookShareButton>
    <TwitterShareButton className="share-venue" url="https://intentionallycatholic.com/Salvation" title="Salvation: Church in Crisis">
      <TwitterIcon size={"2.5rem"} borderRadius={"8px"} />
    </TwitterShareButton>
    <EmailShareButton  className="share-venue" 
      url="https://intentionallycatholic.com/Salvation"
      subject="Salvation: Church in Crisis"
      body="Because you care..."
    >
      <EmailIcon size={"2.5rem"} borderRadius={"8px"} />
    </EmailShareButton>
    <span className="show-hide"><br /></span><span style={{fontStyle: "italic", fontSize: "1.2rem"}}>Share this article with those who care.</span>
  </div >
  )

}

export default ShareSalvation;

import React from 'react';
import './share.scss';
import {TwitterShareButton, TwitterIcon, EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon } from 'react-share';

function ShareForThose()  {

  return (
    <div className="share-container">
    <FacebookShareButton className="share-venue" url="https://intentionallycatholic.com/For-Those-You-Love" >
      <FacebookIcon size={"2.5rem"} borderRadius={"8px"} />
    </FacebookShareButton>
    <TwitterShareButton className="share-venue" url="https://intentionallycatholic.com/For-Those-You-Love" title="For Those You Love">
      <TwitterIcon size={"2.5rem"} borderRadius={"8px"} />
    </TwitterShareButton>
    <EmailShareButton  className="share-venue" 
      url="https://intentionallycatholic.com/For-Those-You-Love"
      subject="For Those You Love"
      body="Sharing this page with you because you care... "
    >
      <EmailIcon size={"2.5rem"} borderRadius={"8px"} />
    </EmailShareButton>
    <span className="show-hide"><br /></span><span style={{fontStyle: "italic", fontSize: "1.2rem"}}>Share this page with those who care.</span>
  </div >
  )

}

export default ShareForThose;

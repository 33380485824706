import React from 'react';
import './share.scss';
import {TwitterShareButton, TwitterIcon, EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon } from 'react-share';

function ShareBelieve()  {

  return (
    <div className="share-container">
    <FacebookShareButton className="share-venue" url="https://intentionallycatholic.com/Believe" >
      <FacebookIcon size={"2.5rem"} borderRadius={"8px"} />
    </FacebookShareButton>
    <TwitterShareButton className="share-venue" url="https://intentionallycatholic.com/Believe" 
      title="A Parish Campaign to Help People Believe">
      <TwitterIcon size={"2.5rem"} borderRadius={"8px"} />
    </TwitterShareButton>
    <EmailShareButton  className="share-venue" 
      url="https://intentionallycatholic.com/Believe"
      subject="A Parish Campaign to Help People Believe"
      body="I'm sharing this page with you so we may help our parishioners believe."
    >
      <EmailIcon size={"2.5rem"} borderRadius={"8px"} />
    </EmailShareButton>
    <span className="show-hide"><br /></span><span style={{fontStyle: "italic", fontSize: "1.2rem"}}>
      Share this page with fellow pastors who want to help their people believe.</span>
  </div >
  )

}

export default ShareBelieve;

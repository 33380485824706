import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import {Helmet} from "react-helmet";
import { Image } from 'react-bootstrap'
import './app.scss';
import clergy from './images/white.jpg';
import Share from './ShareClergy';
import bishopdoc from './docs/Bishop Letter.docx';
import bishoppdf from './docs/Bishop Letter.pdf';
import worddoc1 from './docs/Mailing-Clergy-FIRST-LETTER.docx';
import pdfdoc1 from './docs/Mailing-Clergy-FIRST-LETTER.pdf';
import worddoc2 from './docs/Mailing-Clergy-SALVATION.docx';
import pdfdoc2 from './docs/Mailing-Clergy-SALVATION.pdf';
import worddoc3 from './docs/Mailing-Clergy-CAMPAIGN.docx';
import pdfdoc3 from './docs/Mailing-Clergy-CAMPAIGN.pdf';
import wordVideoScript from './docs/Video-Script.docx';
import pdfVideoScript from './docs/Video-Script.pdf';
import wordVideoNotes from './docs/Video-Homily-Notes.docx';
import pdfVideoNotes from './docs/Video-Homily-Notes.pdf';
import fromSomeone from './images/From Someone Who Loves You.jpg';
import addressSide from './images/AddressSide2.jpg';
import messageSide from './images/MessageSide2.jpg';
import addressArmy from './images/Army Address Clergy.jpg';
import messageArmy from './images/Army Message Clergy.jpg';


function Clergy() {

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('G-P248LQSJST');
    }
  }, []);

  const gaTrack = (category, action) => {
    if (process.env.NODE_ENV === 'production') {
        ReactGA.event({ category, action })
    }
  }

  return (
    <>
      <Helmet>
        <title>Clergy Mission &#124; Intentionally Catholic</title>
      </Helmet>
      <Image src={clergy} className="img-fluid rounded" title="For Clergy" />
      <header>
        <div className="text-center book-clergy" >
          Clergy
        </div>
      </header>

      <div className="book-body" >

        <div className="book-section0" style={{ marginBottom: "0rem" }}>
          <div className="book-section1 book-passing-the-faith">The Mission</div>
          <div className="book-description">
            Banding together with kindred spirits
            who believe all God's words and
            want to share in Christ's saving mission.
          </div>

          <div className="book-section2">Sent to Bishops</div>
          <div className="book-links">
            <span className="book-link" >
              <a href={bishopdoc} onClick={() => gaTrack('Resources', 'Bishops-Word')}
              >Word doc</a></span>
            <span className="book-link" >
              <a href={bishoppdf}
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'Bishops-Pdf')}
              >Pdf</a></span>
            <span><a href="https://docs.google.com/document/d/1EkE3wsu3l6vLFVFC90bjE6P0xgCKeJjN0JEu5-vCphQ/edit?usp=sharing"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'Bishops-GoogleDoc')}
            >Google doc</a></span>
          </div>

          <div className="book-section2">Sent to Pastors</div>
          <div className="book-description">
            <span style={{ fontWeight: "bold" }}>1<sup>st</sup></span> mailing (one page)
          </div>
          <div className="book-links">
            <span className="book-link" >
              <a href={worddoc1} onClick={() => gaTrack('Resources', 'Mailing-Clergy-1-Word')}
              >Word doc</a></span>
            <span className="book-link" >
              <a href={pdfdoc1}
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'Mailing-Clergy-1-Pdf')}
              >Pdf</a></span>
            <span><a href="https://docs.google.com/document/d/1DAmc4Zrfznii0adJnSir7TK97JV4chETxNLTb_eA29w/edit?usp=sharing"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'Mailing-Clergy-1-GoogleDoc')}
            >Google doc</a></span>
          </div>

          <div className="book-description">
            <span style={{ fontWeight: "bold" }}>2<sup>nd</sup></span> mailing (five pages)
          </div>
          <div className="book-links">
            <span className="book-link" >
              <a href={worddoc2} onClick={() => gaTrack('Resources', 'Mailing-Clergy-2-Word')}
              >Word doc</a></span>
            <span className="book-link" >
              <a href={pdfdoc2}
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'Mailing-Clergy-2-Pdf')}
              >Pdf</a></span>
            <span><a href="https://docs.google.com/document/d/15_HwccOGArTYtIzR5O8Qka1NO79GcxbyOnM97N9beEM/edit?usp=sharing"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'Mailing-Clergy-2-GoogleDoc')}
            >Google doc</a></span>
          </div>

          <div className="book-description">
            <span style={{ fontWeight: "bold" }}>3<sup>rd</sup></span> mailing (three pages)
          </div>
          <div className="book-links">
            <span className="book-link" >
              <a href={worddoc3} onClick={() => gaTrack('Resources', 'Mailing-Clergy-3-Word')}
              >Word doc</a></span>
            <span className="book-link" >
              <a href={pdfdoc3}
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'Mailing-Clergy-3-Pdf')}
              >Pdf</a></span>
            <span><a href="https://docs.google.com/document/d/1Zcdk4pI6YOEO7gb-taRqkQ2BOWSEGK4P_dv_ZSZXGCM/edit?usp=sharing"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'Mailing-Clergy-3-GoogleDoc')}
            >Google doc</a></span>
          </div>

          <div className="book-section2">Sent to Bishops, Pastors, and Priests</div>
          <div className="book-description">
            Address and message side of postcard.
          </div>
          <div className="pc-width">
            <div  >
              <Image src={addressSide}
                className="img-fluid rounded"
                title="Address Side"
                style={{ border: "1px solid #DCDCDC"}}
              />
            </div>
            <div  >
              <Image src={messageSide}
                className="img-fluid rounded"
                title="Message Side"
                style={{ border: "1px solid #DCDCDC" }}
              />
            </div>
          </div>
          <div className="pc-width">
            <div  >
              <Image src={addressArmy}
                className="img-fluid rounded"
                title="Address Side"
                style={{ border: "1px solid #DCDCDC"}}
              />
            </div>
            <div  >
              <Image src={messageArmy}
                className="img-fluid rounded"
                title="Message Side"
                style={{ border: "1px solid #DCDCDC" }}
              />
            </div>
          </div>



        </div>

        <div className="book-section0" style={{ marginBottom: "0rem" }}>
          <div className="book-section1 book-the-video">A Parish Campaign</div>
          <div className="book-section2">
            &ldquo;<span style={{fontWeight: "bold"}}>It Makes Sense to Believe</span>&rdquo;
          </div>
          <div className="book-description">
            So all parishioners will hear a case for Christ and His Church &mdash;
            over 12 weeks, use part of homily to preach on a video segment from book. Email
            video ahead to prepare for coming weekend. Spreading the case over weeks
            will help it sink in. See videos and highlights for homilies below. <span style={{fontStyle: "italic"}}>Consider
            launching this campaign in early January, on Divine Mercy Sunday, or in September!</span>
          </div>

          <div className="book-section2">12 Video Segments</div>
          <div className="book-links">

            {/* 1 */}
            <a href="https://youtu.be/f9Kn_ZVVARI"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'clergy-01-should-we')}
            >
              1-Should We Care?
            </a>
            <span className="book-span2"> (9&nbsp;min), </span>

            {/* 2 */}
            <a href="https://youtu.be/qAPaOiQOKHE"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'clergy-02-truth')}
            >
              2-Importance of Truth
            </a>
            <span className="book-span2"> (3&nbsp;min), </span>

            {/* 3 */}
            <a href="https://youtu.be/egVKsIpmIqE"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'clergy-03-is-god-dead')}
            >
              3-Is God Dead?
            </a>
            <span className="book-span2"> (3&nbsp;min), </span>

            {/* 4 */}
            <a href="https://youtu.be/m1BMtAtO23o"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'clergy-04-reveal')}
            >
              4-Did God Reveal Himself?
            </a>
            <span className="book-span2"> (11&nbsp;min), </span>

            {/* 5 */}
            <a href="https://youtu.be/rrfp4G5s6mg"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'clergy-05-Love')}
              style={{ fontWeight: "bold", fontStyle: "italic" }}
            >
              5-Love
            </a>
            <span className="book-span2"> (5&nbsp;min), </span>

            {/* 6 */}
            <a href="https://youtu.be/JHsuE3PYMq8"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'clergy-06-evidence')}
            >
              6-Evidence
            </a>
            <span className="book-span2"> (4&nbsp;min), </span>

            {/* 7 */}
            <a href="https://youtu.be/JUr7l7o6vG8"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'clergy-07-Evil')}
              style={{ fontWeight: "bold", fontStyle: "italic" }}
            >
              7-Evil
            </a>
            <span className="book-span2"> (20&nbsp;min), </span>

            {/* 8 */}
            <a href="https://youtu.be/0Fj5wAPt8UQ"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'clergy-08-suffering-death')}
            >
              8-Suffering &amp; Death
            </a>
            <span className="book-span2"> (9&nbsp;min), </span>

            {/* 9 */}
            <a href="https://youtu.be/16lbIYsre3Y"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'clergy-09-little-faith')}
            >
              9-A Little Faith Is Needed
            </a>
            <span className="book-span2"> (8&nbsp;min), </span>

            {/* 10 */}
            <a href="https://youtu.be/8wDDdkTDI8M"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'clergy-10-sex')}
            >
              10-Sex, Sin &amp; Scandal
            </a>
            <span className="book-span2"> (7&nbsp;min), </span>

            {/* 11 */}
            <a href="https://youtu.be/BCjCYcULNMc"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'clergy-11-women')}
            >
              11-Women's Ordination
            </a>
            <span className="book-span2"> (5&nbsp;min), </span>

            {/* 12 */}
            <a href="https://youtu.be/C84uxYDg9mU"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'clergy-12-making-it')}
            >
              12-Making It into Heaven
            </a>
            <span className="book-span2"> (9&nbsp;min) </span>

          </div>

          <div className="book-section2">Segment Highlights for Homilies</div>
          <div className="book-description">
            <p>Ideally, the preacher will have read the book and watched the video segment. But 
            to save time, I prepared these highlights. Each segment takes about 2 to 4 minutes to read. 
            Please note, the second and third segments were combined into one week because they're short.</p>
          </div>
          <div className="book-links">
            <span className="book-link" >
              <a href={wordVideoNotes} onClick={() => gaTrack('Resources', 'Clergy-VideoNotes-Word')}
              >Word doc</a></span>
            <span className="book-link" >
              <a href={pdfVideoNotes}
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'Clergy-VideoNotes-Pdf')}
              >Pdf</a></span>
            <span><a href="https://docs.google.com/document/d/1nfiILvEhEmuiBHq5WNdDT5dTgxOlk6ek_bUuStP0qzg/edit?usp=sharing"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'Clergy-VideoNotes-GoogleDoc')}
            >Google doc</a></span>
          </div>

          <div className="book-section2">Video Script</div>
          <div className="book-description">
            The video script is available for download, e.g., for copy/paste.
            Also, book manuscripts are likewise available on book websites
            (see <a href="https://www.IntentionallyCatholic.com"
            target="_blank" rel="noopener noreferrer"
            onClick={() => gaTrack('Resources', 'clergy-script-IntentionallyCatholicLink')}
            style={{ fontStyle: "italic", fontWeight: "400" }}>
            main page
          </a>). 
          </div>
          <div className="book-links">
            <span className="book-link" >
              <a href={wordVideoScript} onClick={() => gaTrack('Resources', 'Clergy-VideoScript-Word')}
              >Word doc</a></span>
            <span className="book-link" >
              <a href={pdfVideoScript}
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'Clergy-VideoScript-Pdf')}
              >Pdf</a></span>
            <span><a href="https://docs.google.com/document/d/16f7uI9gGi50tByeVNxjoINBFInijh_bPigp2MwY3S7w/edit?usp=sharing"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'Clergy-VideoScript-GoogleDoc')}
            >Google doc</a></span>
          </div>

        </div>


        <div className="book-section0" style={{ marginTop: "2rem", marginBottom: "3rem" }}>
          <div className="book-section1 book-it-makes-sense" >
            From Someone Who Loves You
          </div>
          <div className="book-description">
            This video can be sent to parishioners to share with fallen-away
            loved ones so they might consider the book's simple case for the faith
            and come to Mass.
          </div>
          <div className="book-links">
            <a href="https://youtu.be/GQb7dBQy6lM"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'FromSomeone-Clergy')}
            >
              <Image src={fromSomeone}
                className="img-fluid rounded"
                title="From Someone Who Loves You"
                style={{ border: "1px solid #DCDCDC" }}
              />
            </a>
            <div className="book-span">(11 minutes)</div>
          </div>
        </div>


        <div className="book-section0" style={{ marginTop: "2rem", marginBottom: "5rem" }}>
          <div className="book-section1 salvation-crisis" >
            Salvation: Church in Crisis
          </div>
          <div className="book-description">
            This article refers to a recent column (December 2020) by
            Cardinal George Pell and to an earlier one by Cardinal
            Avery Dulles. It explores what underlies the widespread
            presumption that almost everyone makes it to heaven
            despite the obvious meaning of Jesus' words. Then it reconsiders
            Jesus' difficult teaching in light of God who is love, the
            crucifixion of his only beloved son, and the intimate reality of heaven.
            The article can be read, downloaded, or watched on
            YouTube.
          </div>
          <div className="book-button-outer" >
            <div className="book-links">
              <a href="https://www.IntentionallyCatholic.com/Salvation"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'GotoSalvation-Clergy')}
                style={{ textDecoration: "none" }}>
                <span
                  className="book-button salvation-crisis"
                  style={{ backgroundColor: "#9b0000" }}
                >
                  Goto Salvation
                </span>
              </a>
            </div>
          </div>
        </div>

        <div className="book-section0" style={{ marginBottom: "6rem" }}>
          <div className="book-section1 book-passing-the-faith" >
            Intentionally Catholic
          </div>
          <div className="book-description">
            Resources for clergy and laity to shore up the faith 
            of parishioners and help pass the faith on to loved ones.
          </div>
          <div className="book-button-outer" >
            <div className="book-links">
              <a href="https://www.IntentionallyCatholic.com"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'Intentionally-Clergy')}
                style={{ textDecoration: "none" }}>
                <span
                  className="book-button"
                  style={{ backgroundColor: "#ca8e4c" }}
                >
                  Goto Main Page
                </span>
              </a>
            </div>
          </div>
        </div>

        <Share />


        <div className="book-section0">
          <div className="book-section1 contact-info">Contact Information</div>
          <div className="book-description">
            Please contact me at:
            <br className="show-hide" /> <a href="mailto: DeaconJohnBeagan@gmail.com">DeaconJohnBeagan@gmail.com</a>
            <br className="show-hide" /> if you have any questions or feedback.
            <br />God bless!
            <div className="signature">
              Deacon John Beagan
            </div>
            <div className="serving">
              Serving Sacred Heart &amp; Saint Patrick parishes in Watertown, MA
            </div>
            <div className="serving">
              Archdiocese of Boston
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default Clergy;

import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Container, Image, Card } from 'react-bootstrap'
import {Helmet} from "react-helmet";
import './app.scss';
import salvation from './images/Conversation.jpg';
import Share from './ShareGeneric';
import word from './docs/Sharing with Loved Ones the Reasons for Our Hope.docx';
import pdf from './docs/Sharing with Loved Ones the Reasons for Our Hope.pdf';
import youtube from './images/youtube_icon.png';


function Sharing() {

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('G-P248LQSJST');
    }
  }, []);

  const gaTrack = (category, action) => {
    if (process.env.NODE_ENV === 'production') {
        ReactGA.event({ category, action })
    }
  }
  return (
    <>
      <Helmet>
        {/* 
            To get SEO to work for other pages, e.g., salvation, 
            must serve index.html page content from node server, e.g., a lambda,
            substituting og meta tags. This should work if direct link,
            but maybe not if react router. Though not sure latter matters.
            Too bad services don't wait for JS to alter meta tags.
        */}
        <title>Reasons for Our Hope &#124; Intentionally Catholic</title>
      </Helmet>
      <Image src={salvation} className="img-fluid rounded" title="Sharing with Loved Ones the Reasons for Our Hope" />
      <header>
        <div className="text-center article article-title" >
        Sharing with Loved Ones the Reasons for Our Hope
        </div>
        <div className="text-center article article-by" >
          May 2022
        </div>
      </header>
      <div className="text-center">
        <div style={{ fontSize: "1.1rem", marginTop: "1rem", marginBottom: "0rem" }}>
          <a href="https://youtu.be/NqnOhfKP5-U"
            target="_blank" rel="noopener noreferrer"
            onClick={() => gaTrack('Resources', 'Reasons-YouTube')}
            style={{ fontStyle: "italic" }}
          >
          <Image src={youtube}
              className="img-fluid rounded"
              title="Video: Sharing with Loved Ones the Reasons for Our Hope"
              width="32px"
              style={{marginRight: "0.5rem"}}
            />
            (Listen)
          </a>
          </div>
      </div>
      <div className="text-center">
        <div style={{ fontSize: "1.1rem", marginTop: "1rem", marginBottom: "0rem" }}>
          <span className="article-link-span" style={{ fontWeight: "bold" }}>Download: </span>
          <span className="article-link-span" >
            <a href={word} onClick={() => gaTrack('Resources', 'Reasons-Word')}
            >Word&nbsp;doc</a></span>
          <span className="article-link-span" >
            <a href={pdf}
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'Reasons-Pdf')}
            >Pdf</a></span>
          <span className="article-link-span">
            <a href="https://docs.google.com/document/d/1ByCn8VpBjrQWVLOReBMiLk2wWM8p-Uw0KMKs_Xj18UQ/edit?usp=sharing"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'Reasons-GoogleDoc')}
            >Google&nbsp;doc</a></span>
        </div>
      </div>

      <div className="book-body article-section" >

        <div className="article article-body">
          <p className="article-para" />

          <p className="article-para" style={{ fontStyle: "italic", fontSize: "125%", marginBottom: "1.5rem", marginTop: "1.5rem" }} >
          This brief article is all that’s needed to initiate conversations with loved ones to bring them back to church.
          </p>

          <p className="article-para" >
          Most of us have people we deeply care about who are not practicing the faith and getting to know and love Jesus. This subject is personal to me because I want all my loved ones prepared to stand before God, the Father of Jesus crucified, when the moment comes. Currently, I hope to bring two siblings and their families back to church; fortunately, we have good relationships. My wife is mostly on board, but there have been times when she has disagreed with the Church’s moral teaching or was frustrated by my attention to spiritual matters. Our three grown sons, thanks be to God, have strong faith and practice it. But this was not always the case.
          </p>

          <p className="article-para">
          In my last article, “<a href="https://intentionallycatholic.com/Dire" target="_blank" rel="noopener noreferrer" >An Intuitive Case for Jesus' Dire Words</a>,” I stressed why practicing our faith is essential for salvation, e.g., “the gate is narrow, and the way is hard, that leads to life, and those who find it are few.” (Matthew 7:14) In the article before that, “<a href="https://intentionallycatholic.com/Army" target="_blank" rel="noopener noreferrer" >Army in the Pews</a>,” I observed the most common reason for Catholics drifting away is doubt and disbelief, and that we in the pews need to help our fallen-away loved ones believe. In both articles, I described why we cannot depend on clergy to care about salvation and present the fullness of what Jesus and his disciples taught. Rather, it is up to each of us to know our faith, initiate conversations with those we love, and encourage them to return to church. If we do not act, then besides God, who will?
          </p>

          <p className="article-para">
          To help prepare for these conversations, I have written this article based on my short book, <span style={{ fontStyle: "italic" }}>Passing the Faith onto Loved Ones</span>, which was endorsed by Ralph Martin. It contains a simple case for believing in God, Jesus, and the one Church he historically established. It also deals briefly with some major controversies and doubts. The manuscript is free to download; its narration is available on YouTube; and the book can be purchased inexpensively on Amazon. (See <a href="https://PassingTheFaithOntoLovedOnes.com" target="_blank" rel="noopener noreferrer" >https://PassingTheFaithOntoLovedOnes.com</a>.)             
          </p>

          <p className="article-para">
          Another helpful book is <span style={{ fontStyle: "italic" }}>Return</span> by Bandon Vogt. <span style={{ fontStyle: "italic" }}>Return</span> is a much more comprehensive resource, while my book focuses on the reasons why it makes most sense to believe as a Catholic vis-à-vis all other options.
          </p>

          <p className="article-para">
          Before I present some highlights from my case to jumpstart conversations, please consider a couple of points. First, I assume we have good relationships with those we want to bring back, i.e., of love, trust, and mutual respect. Otherwise, it would be best to shore this up a bit if possible. I also assume they are in a satisfactory emotional state otherwise we should introduce them to the power of God and his readiness to help.
          </p>

          <p className="article-para">
          Second, we cannot control what our grown children, siblings, and friends believe. God has given all of us the freedom to believe and love, or not. Therefore, we are not responsible for their choices. In fact, we are just one set of hands among many on the divine assembly line of their eternal lives.
          </p>

          <p className="article-para">
          Whether we like it or not, the drama of salvation is thrilling. As God, his angels, and Saints are everywhere on earth, so the devil and his minions are in our homes, churches, seminaries, communities, and wilderness – that we might have choices to love like God or not. When time is up, the reward for persevering in faith is intimate familial union with the Holy Trinity, truly children of God, forever.
          </p>


          <div className="article-sub-title">The Case</div>

          <p className="article-para">
          Relying on evidence and common sense, this case emphasizes what is most likely true. Since we are dealing with invisible realities, and God is preserving our freedom to choose, this is not an ironclad proof as in one plus one equals two. Yet, when the entire case is considered, and a multitude of other reasons not presented here or in my book, I am convinced this is one of the clearest times to believe, next to standing beside Jesus during his many miracles and resurrection.
          </p>

          <p className="article-para">
          When I’m meeting with parents preparing to have their children baptized, I prefer to have a faith conversation with them to see where they are at and bring them closer to Jesus and his Church. I do this by asking an orderly set of questions, listening to their response, then briefly sharing my understanding as needed. My goal is to get them to consider that Jesus and his Church might really be true. I will also try to get them to be concerned about standing before God, the Father of Jesus crucified, and not to be presumptuous. In a relatively safe and prosperous country, some people need a little healthy fear of the Lord.
          </p>

          <p className="article-para">
          This approach is also good for initial conversations with loved ones, but we will likely have more opportunities to talk in coming years as well. The following eight questions and related notes should be sufficient to begin these discussions. We do not need a great amount of knowledge to get started, and we can research any important issues that arise using knowledgeable friends, Bible, Catechism, and Internet.
          </p>

          <div className="article-sub-title">Do You Believe in God?</div>

          <p className="article-para">
          Or what do you believe happens when we die? Are you sure? Is there any evidence for this?
          </p>

          <p className="article-para">
          Astronomers widely accept the Big Bang theory for the origin of the universe. 13.8 billion years ago, all space, matter, and time were condensed into a small point. Then it exploded and the universe began and expanded.             
          </p>

          <p className="article-para">
          Scientists, however, looking for the moment before the bang, see absolutely NOTHING – no space, no matter, and no time. This begs the question, where did the big bang come from? We can believe in an infinite number of universes, but there is no hard evidence for that. On the contrary, there is evidence for God.
          </p>


          <div className="article-sub-title">What Is the Evidence?</div>

          <p className="article-para">
          Miracles, by their number and kind, are supernatural evidence that God exists, Jesus is his beloved son, and Catholicism is the true Church. Throughout the ages, there have been all kinds of miracles, such as, healings, bleeding hosts, incorrupt bodies, Jesus’ burial cloth (Shroud of Turin), Juan Diego’s tilma, and the “miracle of the sun” at Fatima. But one miracle should spark interest and is easy to research since the Internet has information and pictures regarding it. 
          </p>

          <p className="article-para">
          In 1939, Gemma Di Giorgi was born legally blind without pupils in her eyes. Nothing could be done. When she was seven, she was taken to see Padre Pio who miraculously cured her through the power of God. The fascinating thing was she still did not have pupils but could now see. Many Italian doctors examined her.
          </p>


          <div className="article-sub-title">Why Did God Make Us?</div>


          <p className="article-para">
          Given the evidence, and supposing God exists, let us ask, “Why did God create this vast expanding universe with tiny short-lived people on the little planet earth?” The only explanation that makes sense, goes back to when Jesus said, “There is no greater love than to give one’s life for a friend. (John 15:13)
          </p>

          <p className="article-para">
          Think about it. When a person gives his life, then he has given a hundred percent of himself. And no matter how tiny and short-lived that person is, a hundred percent is still a hundred percent. That complete selfless love is worth all creation. I cannot think of any other major religion that puts an emphasis on love as does Christianity and Judaism. In fact, the Gospel writer John states simply and plainly in his first letter that, “God is love.” (1 John 4:8,16)
          </p>



          <div className="article-sub-title">Why Does the God of Love Permit Evil?</div>

          <p className="article-para">
          True love must be given freely; it cannot be programmed like a computer robot, nor coerced as in a master-slave relationship, nor conditioned as in Pavlov’s dog, and neither is it random. That said, though, how God creates people (and angels) with free will and still knows how each is going to use it, is a complete mystery. 
          </p>

          <p className="article-para">
          God’s invisibility and his revelation in words, e.g., Bible and Catechism, are important to our free will. Because if we could see that God was constantly paying attention to us… if we could look at the great joy of heaven whenever we wanted… if the punishment of hell were always visible… we would have to obey God, and thus lose our freedom to truly love. To put it another way, God is not terrorizing us to avoid evil with visions of hell, and God is not bribing us to do good with visions of heaven, rather God wants us to choose love freely, even if at great personal cost in this life – because God’s type of love is selfless.
          </p>


          <div className="article-sub-title">Why Does God Allow Suffering?</div>

          <p className="article-para">
          This question became very important to me after I did my chaplaincy on a hospital cancer floor where patients ranged in age from 18 to 73. I needed to know how suffering was related to love.
          </p>

          <p className="article-para">
          God allows suffering so we might choose to love like God. First, when people are suffering, they’re giving us the divine opportunity to sacrifice our time and resources to help them, as Jesus said, “You did it to me.” (Matthew 25:40) 
          </p>

          <p className="article-para">
          Second, when we are caused to suffer through no fault of our own, we have a divine opportunity to forgive. We might even have to forgive God because this is how He loves us – by the self-sacrifice of his only beloved son, and by forgiving our sins toward Him and neighbor. In fact, like Job and the martyrs, this type of love gives glory to God, because we are loving like Him.
          </p>

          <div className="article-sub-title">What About Church Scandals?</div>

          <p className="article-para">
          From the time of Judas, the Church has always had, and always will have, sinners who commit major scandals – sexual, financial, etc. Jesus warned about this. He foresaw that the Church would have wolves in sheep’s clothing (cf. Matthew 7:15) and that weeds would grow with the wheat until the end of time when the weeds would be gathered and burned. (cf. Matthew 13:24-30) And about Judas, he said it would be better for him if he had never been born. (cf. Matthew 26:24) In the end, there is ultimate justice. 
          </p>

          <div className="article-sub-title">What About Church Teaching on Sex?</div>

          <p className="article-para">
          In the past 60 years the Church has found herself on the wrong side of some very popular cultural issues, especially sexual behavior. This includes, for instance, her prohibitions against pornography, masturbation, homosexual actions, sex outside of marriage, adultery, abortion, in-vitro fertilization, and artificial contraception.
          </p>

          <p className="article-para">
          For a moment let us drop preconceived notions that this teaching erroneously comes from a group of white celibate men who hate sex and are out of touch with modern times. To the contrary, the Church actually holds the sexual act in the highest esteem. In the eyes of God and the Church, sex is holy. Let me say this again. Sexual union is a sacred act.
          </p>

          <p className="article-para">
          When we think about it, it’s obvious. We were made in the image and likeness of God (Genesis 1:27)… our body is a temple of the Holy Spirit within (1 Corinthians 6:19)… and during the sexual act when circumstances are right, a child is conceived. In that moment, the mother, father and God create a child with a mind, body and soul, who will live forever. 
          </p>

          <p className="article-para">
          The Scripture verse that affected me most is where the prophet Malachi (2:15) said the Lord wants “Godly offspring” from marriage. That is, he wants holy children who grow to love God and neighbor more than oneself. This makes perfect sense. God, whose very nature is a loving family, wants us to generously conceive and raise holy children who will be united with him forever. God wants a HUGE LOVING FAMILY – and he asks us to help him create it.
          </p>


          <div className="article-sub-title">Why Does Practicing the Faith Matter?</div>

          <p className="article-para">
          Jesus prayed, “Father, as you are in me and I am in you, may they be one in us.” (John 17:21) That is, entering heaven, being truly children of God, means sharing in His divinity, in intimate union with the Holy Trinity. Consequently, Jesus taught, “You shall love the Lord your God with all your heart, and with all your soul, and with all your mind. This is the great and first commandment.” (Matthew 22:37-38) He also said we must be perfect as our heavenly Father is perfect. (Matthew 5:48) 
          </p>

          <p className="article-para">
          But how can we expect this eternal loving union when we could care less and lived daily as if God did not exist, and Jesus was never born? When seeing a crucifix did not cause us to pause and reconsider? When without moral direction from the Church, we believed what is good is bad, and what is bad is good? 
          </p>

          <p className="article-para">
          To be assured of salvation, we must have an intimate relationship with Jesus through his Church.
          </p>

          <div className="article-sub-title">Conclusion</div>

          <p className="article-para">
          As we grow in knowledge and love of God, we need to initiate conversations with loved ones who have drifted away. The questions and responses here should be enough to get started. Keep in mind, though, that God has given each of us the freedom to believe and love, or not. Moreover, since we have no control over other people’s decisions, we must pray regularly and fervently for those we care about most. In my family, I have literally seen God act in natural and supernatural ways. Even with this confidence, though, we must remain patient and steadfast in faith, hope, and love, and trust God to do what is best.
          </p>

          <Share 
            url = "https://IntentionallyCatholic.com/Dire"
            title = "An Intuitive Case for Jesus' Dire Words"
            subject = "An Intuitive Case for Jesus' Dire Words"
            body = ""
            caption = "Share this article with those who care."        
          />

        </div>

        <div className="book-section0">
          <div className="book-section1 contact-info">Contact Information</div>
          <div className="book-description">
            Please contact me at:
            <br className="show-hide" /> <a href="mailto: DeaconJohnBeagan@gmail.com">DeaconJohnBeagan@gmail.com</a>
            <br className="show-hide" /> if you have any questions or feedback.
            <br />God bless!
            <div className="signature">
              Deacon John Beagan
            </div>
            <div className="serving">
              Serving Sacred Heart &amp; Saint Patrick parishes in Watertown, MA
            </div>
            <div className="serving">
              Archdiocese of Boston
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default Sharing;
